import i18n from 'i18next';

import {resize} from '@pexip/media-processor';
import type {ImageRecord as BitmapRecord} from '@pexip/media-processor';
import {notificationToastSignal} from '@pexip/components';

import {
    PROCESSING_HEIGHT,
    PROCESSING_WIDTH,
    IMAGE_DATABASE_NAME,
    IMAGE_DATABASE_VERSION,
    IMAGE_OBJECTSTORE_NAME,
    IMAGE_OBJECTSTORE_KEY_PATH,
    IMAGE_OBJECTSTORE_KEY_NAME,
    USER_CUSTOM_IMAGE_KEY,
} from '../constants';
import type {ImageRecord} from '../types';
import {logger} from '../logger';
import {userCustomImageSignal} from '../signals/ImageStore.signals';
import {toImageBitmapRecord} from '../utils/toBitmapRecord';

type Close = () => void;

interface Props {
    imageUrl: string[];
    bitmapRecord?: BitmapRecord;
    imageRecord?: ImageRecord;
}

const createCanvas = (width: number, height: number) => {
    if ('OffscreenCanvas' in self) {
        return new OffscreenCanvas(width, height);
    }
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
};

/**
 * Concert canvas to Blob with type set to image/png for maximum compatibility.
 */
const createBlob = (
    canvas: HTMLCanvasElement | OffscreenCanvas,
    quality: number,
) => {
    if (canvas instanceof HTMLCanvasElement) {
        return new Promise<Blob | null>(resolve => {
            canvas.toBlob(resolve, 'image/png', quality);
        });
    }
    return canvas.convertToBlob({quality, type: 'image/png'});
};

/**
 * Create an image store to manage the user custom image.
 */
export const createImageStore = () => {
    const internal = {
        imageMap: new Map<string, ImageRecord>(),
        imageUrl: [] as string[],

        canvas: createCanvas(PROCESSING_WIDTH, PROCESSING_HEIGHT),
    };
    const props: Props = {
        get imageUrl() {
            return internal.imageUrl;
        },
    };

    /**
     * Update the internal state with the new image record.
     * @param record - The new image record.
     */
    const updateInternal = async (record: ImageRecord) => {
        props.imageRecord = record;
        // Clean up the old image
        for (const url of internal.imageUrl) {
            URL.revokeObjectURL(url);
            internal.imageMap.delete(url);
        }
        const url = URL.createObjectURL(props.imageRecord.data);
        internal.imageUrl = [url];
        internal.imageMap.set(url, props.imageRecord);

        const bitmapRecord = await toImageBitmapRecord(record);
        props.bitmapRecord?.image.close();
        props.bitmapRecord = bitmapRecord;

        // Notify the subscribers
        userCustomImageSignal.emit(record);
    };

    /**
     * Open the database and handle the upgrade event.
     * @param name - The name of the database.
     * @param version - The version of the database.
     */
    const open = (
        name: string,
        version: number,
        handleUpgradeNeeded: (
            db: IDBDatabase,
            event: IDBVersionChangeEvent,
        ) => void,
    ) =>
        new Promise<{
            db: IDBDatabase;
            close: () => void;
        }>((resolve, reject) => {
            const request = indexedDB.open(name, version);
            const handleUpgrade = (event: IDBVersionChangeEvent) => {
                const db = request.result;
                handleUpgradeNeeded(db, event);
            };

            const unsubscribe = () => {
                request.removeEventListener('upgradeneeded', handleUpgrade);
                request.removeEventListener('error', handleError);
                request.removeEventListener('success', handleSuccess);
            };
            const handleSuccess = () => {
                resolve({
                    db: request.result,
                    close: () => {
                        request.result.close();
                        unsubscribe();
                    },
                });
            };
            const handleError = () => {
                const error = request.error;
                logger.error(
                    {error},
                    `Error occurred while opening ${name}@v${version} DB`,
                );
                unsubscribe();
                reject(error);
            };
            request.addEventListener('upgradeneeded', handleUpgrade);
            request.addEventListener('error', handleError);
            request.addEventListener('success', handleSuccess);
        });
    /**
     * Begin a transaction on the database.
     * @param db - The database to begin the transaction on.
     * @param storeNames - The names of the object stores to include in the transaction.
     * @param mode - The mode of the transaction.
     * @returns The transaction and a function to end the transaction.
     * @throws DOMException - If an error occurs during the transaction.
     */
    const beginTransaction = (
        db: IDBDatabase,
        storeNames: string[],
        mode: IDBTransactionMode,
    ) => {
        const transaction = db.transaction(storeNames, mode);
        const handleError = () => {
            const error = transaction.error;
            logger.error(
                {error, storeNames, mode, db},
                `Error occurred during ${storeNames} transaction DB.`,
            );
            if (error) {
                throw error;
            }
        };
        const handleComplete = () => {
            logger.info(
                {storeNames, mode, db},
                `Complete ${storeNames} transaction successfully.`,
            );
        };
        const handleAbort = () => {
            logger.info(
                {storeNames, mode, db},
                `Abort ${storeNames} transaction!`,
            );
        };
        transaction.addEventListener('error', handleError);
        transaction.addEventListener('abort', handleAbort);
        transaction.addEventListener('complete', handleComplete);
        return {
            transaction,
            endTransaction: () => {
                transaction.removeEventListener('error', handleError);
                transaction.removeEventListener('abort', handleAbort);
                transaction.removeEventListener('complete', handleComplete);
            },
        };
    };
    /**
     * Handle the upgrade event by creating the object store.
     * @param db - The database to upgrade.
     * @param event - The upgrade event.
     */
    const upgradeImageStore = (
        db: IDBDatabase,
        event: IDBVersionChangeEvent,
    ) => {
        logger.info({event, db}, 'Handle upgradeneeded event');
        const objectStore = db.createObjectStore(IMAGE_OBJECTSTORE_NAME, {
            keyPath: 'key',
        });
        objectStore.createIndex(
            IMAGE_OBJECTSTORE_KEY_NAME,
            IMAGE_OBJECTSTORE_KEY_PATH,
            {unique: true},
        );
    };
    /**
     * Wrap IDBRequest in a promise.
     */
    const request = <T>(storeRequest: IDBRequest<T>, message: string) =>
        new Promise<T>((resolve, reject) => {
            const handleError = () => {
                const error = storeRequest.error;
                logger.error({error}, message);
                reject(error);
            };
            const handleSuccess = () => {
                logger.info({result: storeRequest.result}, message);
                resolve(storeRequest.result);
            };
            storeRequest.addEventListener('error', handleError);
            storeRequest.addEventListener('success', handleSuccess);
        });
    /**
     * Write the user custom image in the database.
     * @param name - The name of the image.
     * @param data - The image data.
     */
    const write = async (name: string, data: Blob) => {
        let closeDB: Close | undefined;
        try {
            const {db, close} = await open(
                IMAGE_DATABASE_NAME,
                IMAGE_DATABASE_VERSION,
                upgradeImageStore,
            );
            closeDB = close;
            const {transaction, endTransaction} = beginTransaction(
                db,
                [IMAGE_OBJECTSTORE_NAME],
                'readwrite',
            );
            const objectStore = transaction.objectStore(IMAGE_OBJECTSTORE_NAME);
            await request(
                objectStore.put({
                    key: USER_CUSTOM_IMAGE_KEY,
                    name,
                    data,
                }),
                `Write image[${name}] to DB.`,
            );
            endTransaction();
            closeDB();
        } catch (error) {
            logger.error(
                {error, data},
                `Error occurred while writing image[${name}] to DB`,
            );
            closeDB?.();
        }
    };
    /**
     * Retrieve the user custom image from the database.
     * @returns The user custom image record.
     */
    const retrieve = async () => {
        let closeDB: Close | undefined;
        try {
            const {db, close} = await open(
                IMAGE_DATABASE_NAME,
                IMAGE_DATABASE_VERSION,
                upgradeImageStore,
            );
            closeDB = close;
            const {transaction, endTransaction} = beginTransaction(
                db,
                [IMAGE_OBJECTSTORE_NAME],
                'readonly',
            );
            const objectStore = transaction.objectStore(IMAGE_OBJECTSTORE_NAME);
            const record = await request<ImageRecord | undefined>(
                objectStore.get(USER_CUSTOM_IMAGE_KEY),
                'Get user custom image.',
            );
            endTransaction();
            if (record) {
                await updateInternal(record);
            }
            closeDB();
            return record;
        } catch (error) {
            logger.error(
                {error},
                'Error occurred while reading user custom image',
            );
            closeDB?.();
        }
    };

    /**
     * Load and optimize the image.
     * @param file - The image file to load.
     * @param quality - The quality of the image to be optimized.
     * @throws Error - If an error occurs while loading and optimizing the image.
     */
    const loadAndOptimizeImage = async (file: File, quality = 0.8) => {
        // Load directly as an ImageBitmap so we can load into Canvas smoothly
        const bitmap = await createImageBitmap(file);
        logger.info(
            {image: bitmap},
            `Image Loaded: ${file.name} ${bitmap.width}x${bitmap.height}`,
        );
        const ctx = internal.canvas.getContext('2d', {
            alpha: false,
        }) as CanvasRenderingContext2D;
        // Size optimization: Resize the image to our desired size
        const rects = resize(
            bitmap.width,
            bitmap.height,
            PROCESSING_WIDTH,
            PROCESSING_HEIGHT,
        );
        // Resize Image to our desired size
        ctx?.drawImage(
            bitmap,
            rects.sx,
            rects.sy,
            rects.sw,
            rects.sh,
            rects.dx,
            rects.dy,
            rects.dw,
            rects.dh,
        );
        // Convert the canvas to a Blob also optimize the image quality
        const blob = await createBlob(internal.canvas, quality);
        // Close the bitmap as it is no longer needed
        bitmap.close();
        if (!blob) {
            throw new Error('Unable to Blob from canvas');
        }
        return blob;
    };

    /**
     * Save the image to the database.
     * @param file - The image file to save.
     */
    const save = async (file: File) => {
        try {
            const blob = await loadAndOptimizeImage(file);
            await write(file.name, blob);
            await updateInternal({
                name: file.name,
                data: blob,
                key: USER_CUSTOM_IMAGE_KEY,
            });
        } catch (error) {
            logger.error(
                {error},
                `Error occurred while reading and saving image ${file.name}`,
            );

            notificationToastSignal.emit([
                {
                    message: i18n.t(
                        'media.upload-failed',
                        'File upload failed',
                    ),
                },
            ]);
        }
    };
    return {
        /**
         * Read the user custom image from the Image Store.
         */
        read: () => {
            void retrieve();
        },
        /**
         * Save the user custom image to the Image Store.
         */
        save: (file: File) => {
            void save(file);
        },
        /**
         * Get the ImageRecord with the provided url.
         */
        resolveUserCustomImageUrl: (url?: string) => {
            if (!url) {
                return undefined;
            }
            return internal.imageMap.get(url);
        },
        /**
         * Get the current BitmapRecord.
         */
        getBitmapRecord: () => {
            return props.bitmapRecord;
        },
        /**
         * Get the current url.
         */
        getUserCustomImageUrl: () => {
            return props.imageUrl;
        },
    };
};

export const imageStore = createImageStore();

export type ImageStore = ReturnType<typeof createImageStore>;
