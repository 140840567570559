import {useEffect, useState} from 'react';

import type {Signal} from '@pexip/signal';
import {useMdDown} from '@pexip/components';

import {isAppEntryUrl} from '../utils/isAppEntryUrl';

export const getShouldRenderMobileGetStartedPageHook =
    (mobileGetStartedNextSignal: Signal<undefined>) => () => {
        const isMdDown = useMdDown();
        const [
            hasProceededMobileGetStartedStep,
            setHasProceededMobileGetStartedStep,
        ] = useState(!isMdDown);

        useEffect(() => {
            mobileGetStartedNextSignal.add(() => {
                setHasProceededMobileGetStartedStep(true);
            });
        }, [mobileGetStartedNextSignal]);

        return (
            isAppEntryUrl(location.href) &&
            isMdDown &&
            !hasProceededMobileGetStartedStep
        );
    };
