import type {GeneratedMediaSignals} from '@pexip/media';

import type {Subscribe} from '../types';

export const createChangeOfMediaDevicesSubscription =
    (
        signals: GeneratedMediaSignals<'onMediaChanged' | 'onDevicesChanged'>,
    ): Subscribe =>
    onChange => {
        const subscriptions = [
            signals.onMediaChanged.add(onChange),
            signals.onDevicesChanged.add(onChange),
        ];
        return () => {
            for (const unsubscribe of subscriptions) {
                unsubscribe();
            }
        };
    };

export const createChangeOfTrackSubscription =
    (
        signals: GeneratedMediaSignals<
            'onMediaChanged' | 'onAddTrack' | 'onRemoveTrack'
        >,
        kind: 'audio' | 'video',
    ): Subscribe =>
    onChange => {
        const changeTrack = (track: MediaStreamTrack) => {
            if (track.kind === kind) {
                onChange();
            }
        };
        const unsubscribes = [
            signals.onMediaChanged.add(onChange),
            signals.onAddTrack.add(changeTrack),
            signals.onRemoveTrack.add(changeTrack),
        ];
        return () => {
            for (const unsubscribe of unsubscribes) {
                unsubscribe();
            }
        };
    };

export const createMediaActiveSubscription =
    (
        signals: GeneratedMediaSignals<
            | 'onMediaChanged'
            | 'onAddTrack'
            | 'onRemoveTrack'
            | 'onTrackReleased'
        >,
    ): Subscribe =>
    onChange => {
        const unsubscribes = [
            signals.onMediaChanged.add(onChange),
            signals.onAddTrack.add(onChange),
            signals.onRemoveTrack.add(onChange),
            signals.onTrackReleased.add(onChange),
        ];
        return () => {
            for (const unsubscribe of unsubscribes) {
                unsubscribe();
            }
        };
    };
