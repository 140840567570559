import type {Dispatch, RefObject} from 'react';
import {useEffect} from 'react';

import type {IScrollbars} from '@pexip/components';

import {
    NewChatMessageAction,
    scrollToLastMessage,
    calculateNewChatMessageAction,
} from '../utils/chat';

import type {NewUnreadChatMessageActions} from './useChat';

export const useDisplayNewChatMessagePill = (
    state: {
        isMessageByCurrentUser: boolean;
        isLastMessageVisible: boolean;
        messageCount: number;
        scrollbarRef: RefObject<IScrollbars>;
    },
    setDisplayNewMessagePill: (
        displayNewMessageToast: boolean,
    ) => NewUnreadChatMessageActions,
    dispatch: Dispatch<NewUnreadChatMessageActions>,
) => {
    const {
        isMessageByCurrentUser,
        isLastMessageVisible,
        messageCount,
        scrollbarRef,
    } = state;

    // biome-ignore lint/correctness/useExhaustiveDependencies: /FIXME: should be depends on messages changes only
    useEffect(() => {
        const action = calculateNewChatMessageAction(
            isMessageByCurrentUser,
            isLastMessageVisible,
        );

        switch (action) {
            case NewChatMessageAction.ScrollToLastMessage:
                scrollToLastMessage(scrollbarRef);
                break;
            case NewChatMessageAction.DisplayNewMessageToast:
                dispatch(setDisplayNewMessagePill(true));
                break;
            default:
                break;
        }
    }, [messageCount]);
};
