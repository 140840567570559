import type {AutoHideUIInterfacesState} from '@pexip/hooks';

import type {InMeetingUIState, InMeetingUIStateAPI} from '../types';

/**
 * Initializes `InMeetingUIState`  and returns `InMeetingUIStateAPI`.
 *
 * Enable/disable the auto-ui-hide feature is based on user interaction, such open/close/focus/blur which triggers the change.
 * Therefore, it there's no need to have explicit `useState/useReducer` to manage the state.
 */
export const initMeetingUIState = (): InMeetingUIStateAPI => {
    let _uiVisibilityState: AutoHideUIInterfacesState = 'visible';
    let meetingUIState = {
        unreadChatMessages: false,
        focusedButton: false,
        openedMenuModal: false,
        openedSettingModal: false,
        openedInviteModal: false,
        openedPanel: false,
        openedUserMenu: false,
        openedMoreControls: false,
        openedPlugins: false,
        openedTranscripts: false,
    };

    return {
        setMeetingUIState: (next: InMeetingUIState) => {
            meetingUIState = next;
        },
        get currentState() {
            return meetingUIState;
        },
        get uiVisibilityState() {
            return _uiVisibilityState;
        },
        setUIVisibilityState: (
            uiVisibilityState: AutoHideUIInterfacesState,
        ) => {
            _uiVisibilityState = uiVisibilityState;
        },
        shouldEnableAutoHide: () =>
            !Object.values(meetingUIState).some(isVisible => isVisible),
    };
};
