import {useCallback, useState} from 'react';

import type {MediaDeviceInfoLike, IndexedDevices} from '@pexip/media-control';

import {logger} from '../logger';
import {getAudioOutput} from '../utils/getAudioOutput';

export const createPreviewAudioOutputHook =
    (setAudioOutput: (value: MediaDeviceInfoLike) => void) =>
    (devices: IndexedDevices, audioOutput?: MediaDeviceInfoLike) => {
        const [preview, setPreview] = useState<MediaDeviceInfoLike | undefined>(
            getAudioOutput(devices, audioOutput),
        );

        const applyChanges = useCallback(() => {
            if (preview && preview.deviceId !== audioOutput?.deviceId) {
                logger.debug({audioOutput: preview}, 'Save AudioOutput');
                setAudioOutput(preview);
            }
            return false;
        }, [audioOutput?.deviceId, preview, setAudioOutput]);

        const hasChanges = useCallback(
            () =>
                Boolean(preview && preview.deviceId !== audioOutput?.deviceId),
            [audioOutput?.deviceId, preview],
        );

        return {
            preview,
            applyChanges,
            hasChanges,
            setPreview,
        };
    };
