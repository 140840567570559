import type {
    Analyzer,
    AnalyzerSubscribableOptions,
    AudioGraph,
    AudioGraphOptions,
    AudioNodeInit,
    AudioNodeProps,
    AudioNodeInitConnections,
    Gain,
    UniversalAudioContextState,
    Unsubscribe,
    WorkletMessagePortOptions,
    AudioNodeInitConnectParam,
    BaseAudioNode,
    WorkletModule,
} from './types';
import {copyByteBufferToFloatBuffer, processAverageVolume} from './process';
import {createDenoiseWorkletNode} from './workletNodes';
import {
    createAnalyserNode,
    createChannelSplitterNode,
    createGainNode,
    createMediaStreamAudioDestinationNode,
    createMediaStreamAudioSourceNode,
    createMediaStreamAudioClone,
    createDelayNode,
    muteToGain,
    stopStreamTracks,
    createMediaElementSourceNode,
    createChannelMergerNode,
} from './utils';
import {isAudioParam, isAudioNodeInit} from './typeGuards';

/**
 * A function to create `AudioContext` using constructor or factory function
 * depends on the browser supports
 *
 * @param options - @see {@link AudioContextOptions}
 *
 * @internal
 */
export const createAudioContext = (
    options?: AudioContextOptions,
): AudioContext => {
    try {
        return new AudioContext(options);
    } catch {
        return new window.webkitAudioContext(options);
    }
};

/**
 * Resume the stream whenever interrupted
 *
 * @param audioContext - AudioContext
 *
 * @alpha
 */
export function resumeAudioOnInterruption(audioContext: AudioContext) {
    const resumeInterrupted = () => {
        // Resume the stream whenever Safari has interrupted it
        if (
            (audioContext.state as UniversalAudioContextState) === 'interrupted'
        ) {
            void audioContext.resume();
        }
    };
    audioContext.addEventListener('statechange', resumeInterrupted);
    return () => {
        audioContext.removeEventListener('statechange', resumeInterrupted);
    };
}

/**
 * Resume the AudioContext whenever the source track is unmuted
 *
 * @param audioContext - The `AudioContext` to resume
 *
 * @alpha
 */
export const resumeAudioOnUnmute =
    (context: AudioContext) =>
    /**
     * @param track - The source track to listen on the `unmute` event
     */
    (track: MediaStreamTrack): Unsubscribe => {
        const resume = () => {
            if (context.state === 'suspended') {
                void context.resume();
            }
        };
        track.addEventListener('unmute', resume);
        return () => {
            track.removeEventListener('unmute', resume);
        };
    };

/**
 * Wrap GainNode with mute function
 *
 * @param context - @see {@link AudioContext}
 * @param muteInit - initial mute state of the node
 *
 * @internal
 */
const createGainWithMute = (context: AudioContext, muteInit = false): Gain => {
    let mute = muteInit;
    const gain = createGainNode(context, {
        gain: muteToGain(mute),
        channelCountMode: 'explicit',
    });
    const disconnect = gain.disconnect.bind(gain);
    const connect = gain.connect.bind(gain);

    return {
        get node() {
            return gain;
        },

        get mute() {
            return mute;
        },

        set mute(shouldMute: boolean) {
            mute = shouldMute;
            const time = context.currentTime;
            if (Number.isFinite(time)) {
                gain.gain.setValueAtTime(muteToGain(shouldMute), time);
            } else {
                throw new Error('Set gain with non-finite time value');
            }
        },

        connect,
        disconnect,
    };
};

/**
 * Wrap AnalyserNode with polyfill and able to get average volume
 *
 * @param context - @see {@link AudioContext}
 * @param options - @see {@link AnalyserOptions}
 *
 * @internal
 */
const createAnalyzer = (
    context: AudioContext,
    options?: AnalyserOptions,
): Analyzer => {
    const analyser = createAnalyserNode(context, options);
    const getByteFrequencyData = analyser.getByteFrequencyData.bind(analyser);
    const getByteTimeDomainData = analyser.getByteTimeDomainData.bind(analyser);
    const getFloatFrequencyData = analyser.getFloatFrequencyData.bind(analyser);

    const connect = analyser.connect.bind(analyser);
    const disconnect = analyser.disconnect.bind(analyser);

    let byteBuffer: Uint8Array;

    /**
     * Safari does not support getFloatTimeDomainData API, thus polyfill is
     * needed
     *
     * According to https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/getFloatTimeDomainData
     */
    const getFloatTimeDomainData = (buffer: Float32Array) => {
        if ('getFloatTimeDomainData' in AnalyserNode.prototype) {
            return analyser.getFloatTimeDomainData(buffer);
        }
        // Use getByteTimeDomainData and convert the result to the buffer
        if (!byteBuffer) {
            byteBuffer = new Uint8Array(buffer.length);
        }

        analyser.getByteTimeDomainData(byteBuffer);
        return copyByteBufferToFloatBuffer(byteBuffer, buffer);
    };

    const getAverageVolume = (buffer: Float32Array) => {
        getFloatTimeDomainData(buffer);
        return processAverageVolume(Array.from(buffer));
    };

    return {
        get node() {
            return analyser;
        },

        get frequencyBinCount() {
            return analyser.frequencyBinCount;
        },

        get fftSize() {
            return analyser.fftSize;
        },
        set fftSize(size: number) {
            analyser.fftSize = size;
        },

        get minDecibels() {
            return analyser.minDecibels;
        },
        set minDecibels(decibels: number) {
            analyser.minDecibels = decibels;
        },

        get maxDecibels() {
            return analyser.maxDecibels;
        },
        set maxDecibels(decibels: number) {
            analyser.maxDecibels = decibels;
        },

        get smoothingTimeConstant() {
            return analyser.smoothingTimeConstant;
        },
        set smoothingTimeConstant(constant: number) {
            analyser.smoothingTimeConstant = constant;
        },

        getByteTimeDomainData,
        getByteFrequencyData,
        getFloatFrequencyData,
        getFloatTimeDomainData,
        getAverageVolume,

        connect,
        disconnect,
    };
};

/**
 * Subscribe MessagePort message from an AudioWorkletNode
 *
 * @param workletNode - the node to subscribe
 * @param options - can pass a message handler here to handle the message
 */
export const subscribeWorkletNode = <T>(
    workletNode: AudioWorkletNode,
    {messageHandler, errorHandler}: Partial<WorkletMessagePortOptions<T>> = {},
) => {
    const subscribeMessage = (event: MessageEvent) => {
        messageHandler?.(event.data);
    };

    const subscribeToPortError = (event: MessageEvent) => {
        if (errorHandler) {
            errorHandler(event);
        }
    };

    const subscribeToProcessorError = (event: Event) => {
        if (errorHandler) {
            errorHandler(event);
        }
    };

    workletNode.port.addEventListener('message', subscribeMessage);
    workletNode.port.start();

    workletNode.addEventListener('processorerror', subscribeToProcessorError);
    workletNode.port.addEventListener('messageerror', subscribeToPortError);

    return () => {
        workletNode.port.postMessage({type: 'release'});
        workletNode.port.removeEventListener('message', subscribeMessage);
        workletNode.removeEventListener(
            'processorerror',
            subscribeToProcessorError,
        );
        workletNode.port.removeEventListener(
            'messageerror',
            subscribeToPortError,
        );
    };
};

/**
 * Offset factor to compensate real-time audio processing since `setTimeout` is
 * not guaranteed to execute at provided delay and we need this adjustment
 * factor to compensate the delay to get a result similar what we did with
 * `AudioWorklet`.
 */
const TIMEOUT_DELAY_ADJUSTMENT = 0.5;

/**
 * Subscribe to a timeout loop to get the data from Analyzer
 *
 * @param analyzer - the analyzer to subscribe
 * @param options - message handler, etc.
 */
export const subscribeTimeoutAnalyzerNode = (
    analyzer: Analyzer,
    {messageHandler, updateFrequency = 2.0}: AnalyzerSubscribableOptions,
) => {
    // A timeout-based approach
    const timeoutMs = updateFrequency * 1000 * TIMEOUT_DELAY_ADJUSTMENT;
    let timeoutId = 0;
    let stopTimeout = false;

    const clearTimeout = () => {
        if (timeoutId) {
            window.clearTimeout(timeoutId);
            timeoutId = 0;
        }
    };

    const process = () => {
        messageHandler(analyzer);

        clearTimeout();
        if (!stopTimeout) {
            timeoutId = window.setTimeout(process, timeoutMs);
        }
    };

    process();

    return () => {
        stopTimeout = true;
        clearTimeout();
    };
};

function createBaseAudioNode<
    T extends AudioNode = AudioNode,
    R extends BaseAudioNode = AudioNode,
>(
    name: string,
    create: AudioNodeInit<T, R>['create'],
    release?: AudioNodeInit<T, R>['release'],
): AudioNodeInit<T, R> {
    const props: AudioNodeProps<T, R> = {
        name,
        node: undefined,
        audioNode: undefined,
        outputs: new WeakSet(),
    };

    const createNode: AudioNodeInit<T, R>['create'] = (context, prevNode) => {
        const [audioNode, node] = create(context, prevNode);
        props.audioNode = audioNode;
        props.node = node;
        return [audioNode, node];
    };
    const releaseNode: AudioNodeInit<T, R>['release'] = () => {
        release?.();
        props.outputs = new WeakSet();
        props.audioNode?.disconnect();
        props.audioNode = undefined;
    };

    const connectNode = (param: AudioNodeInitConnectParam) => {
        if (!param) {
            return;
        }
        if (!props.audioNode) {
            throw new Error('Source AudioNode is not initialized');
        }
        const [destination, output, input] = Array.isArray(param)
            ? param
            : [param];
        if (isAudioParam(destination)) {
            props.outputs.add(destination);
            return props.audioNode.connect(destination, output);
        }
        if (isAudioNodeInit(destination)) {
            if (!destination.audioNode) {
                throw new Error('Destination AudioNode is not initialized');
            }
            props.outputs.add(destination);
            return props.audioNode.connect(
                destination.audioNode,
                output,
                input,
            );
        }
    };

    const disconnectNode = (
        destInit: AudioNodeInitConnectParam | undefined,
    ) => {
        if (!destInit) {
            return;
        }
        if (!props.audioNode) {
            throw new Error('AudioNode is not initialized');
        }
        const [destination, output, input] = Array.isArray(destInit)
            ? destInit
            : [destInit];
        if (destination && !props.outputs.has(destination)) {
            return; // There is no connection between the nodes
        }
        if (isAudioParam(destination)) {
            props.outputs.delete(destination);
            if (output !== undefined) {
                return props.audioNode.disconnect(destination, output);
            }
            return props.audioNode.disconnect(destination);
        }
        if (isAudioNodeInit(destination)) {
            if (!destination.audioNode) {
                throw new Error('Destination AudioNode is not initialized');
            }
            props.outputs.delete(destination);
            if (output !== undefined) {
                if (input !== undefined) {
                    return props.audioNode.disconnect(
                        destination.audioNode,
                        output,
                        input,
                    );
                }
                return props.audioNode.disconnect(
                    destination.audioNode,
                    output,
                );
            }
            return props.audioNode.disconnect(destination.audioNode);
        }
        props.outputs = new WeakSet();
        props.audioNode.disconnect();
    };

    const hasConnectedTo: AudioNodeInit<T>['hasConnectedTo'] = init => {
        return props.outputs.has(init);
    };

    return Object.assign(props, {
        create: createNode,
        connect: connectNode,
        disconnect: disconnectNode,
        release: releaseNode,
        hasConnectedTo,
        toJSON: () => ({
            name: props.name,
            node: props.node,
            audioNode: props.audioNode,
        }),
    });
}

/**
 * Create a MediaStreamAudioSourceNodeInit
 *
 * @param mediaStream - Source MediaStream
 * @param shouldResetEnabled - Whether or not to enable the cloned track
 */
export const createStreamSourceGraphNode = (
    mediaStream: MediaStream,
    shouldResetEnabled = true,
) => {
    let stream: MediaStream | undefined = undefined;
    let unsubscribeUnmutes: Array<() => void> | undefined = undefined;
    return createBaseAudioNode(
        'source',
        context => {
            stream = createMediaStreamAudioClone(mediaStream);
            // Enabled the track for the cloned stream
            if (shouldResetEnabled) {
                for (const track of stream.getAudioTracks()) {
                    track.enabled = true;
                }
            }
            unsubscribeUnmutes = mediaStream
                .getAudioTracks()
                .map(resumeAudioOnUnmute(context));
            const node = createMediaStreamAudioSourceNode(context, {
                mediaStream: stream,
            });
            return [node, node];
        },
        () => {
            stopStreamTracks(stream);
            for (const unsubscribe of unsubscribeUnmutes ?? []) {
                unsubscribe();
            }
            stream = undefined;
        },
    );
};

/**
 * Create a MediaStreamAudioSourceNodeInit
 *
 * @param mediaStream - Source MediaStream
 */
export const createMediaElementSourceGraphNode = (
    mediaElement: HTMLMediaElement,
) => {
    return createBaseAudioNode('source', context => {
        const node = createMediaElementSourceNode(context, {
            mediaElement,
        });
        return [node, node];
    });
};

/**
 * Create an analyzer node with push-based subscription
 */
export const createAnalyzerSubscribableGraphNode = ({
    messageHandler,
    updateFrequency = 2.0,
    ...analyserOptions
}: AnalyzerSubscribableOptions & AnalyserOptions) => {
    let unsubscribe: Unsubscribe | undefined;
    return createBaseAudioNode(
        'analyzer',
        context => {
            const node = createAnalyzer(context, analyserOptions);
            // Subscribe to the timeout callback
            unsubscribe = subscribeTimeoutAnalyzerNode(node, {
                messageHandler,
                updateFrequency,
            });
            return [node.node, node];
        },
        () => {
            unsubscribe?.();
            unsubscribe = undefined;
        },
    );
};

/**
 * Create a noise suppression node
 *
 * @param data - WebAssembly source
 */
export const createDenoiseWorkletGraphNode = (
    data: BufferSource,
    messageHandler?: (vads: number[]) => void,
) => {
    let unsubscribe: Unsubscribe | undefined;
    return createBaseAudioNode(
        'denoise',
        (context, prevNode) => {
            const sampleRate = context.sampleRate;
            const channelCount = prevNode?.channelCount ?? 1;
            const node = createDenoiseWorkletNode(context, {
                outputChannelCount: [channelCount],
                processorOptions: {
                    data,
                    sampleRate,
                    shouldSendVAD: !!messageHandler,
                },
            });
            unsubscribe = subscribeWorkletNode(node, {messageHandler});
            return [node, node];
        },
        () => {
            unsubscribe?.();
            unsubscribe = undefined;
        },
    );
};

/**
 * Create a GainNodeInit
 *
 * @param mute - initial mute state
 */
export const createGainGraphNode = (mute: boolean) => {
    return createBaseAudioNode('gain', context => {
        const node = createGainWithMute(context, mute);
        return [node.node, node];
    });
};

/**
 * Create an AnalyzerNodeInit
 *
 * @param options - @see {@link AnalyserOptions}
 */
export const createAnalyzerGraphNode = (options?: AnalyserOptions) => {
    return createBaseAudioNode('analyzer', context => {
        const node = createAnalyzer(context, options);
        return [node.node, node];
    });
};

/**
 * Create a MediaStreamAudioDestinationNodeInit
 */
export const createStreamDestinationGraphNode = (
    options?: AudioNodeOptions,
) => {
    let node: undefined | MediaStreamAudioDestinationNode = undefined;
    return createBaseAudioNode(
        'destination',
        context => {
            node = createMediaStreamAudioDestinationNode(context, options);
            return [node, node];
        },
        () => {
            stopStreamTracks(node?.stream);
            node = undefined;
        },
    );
};

/**
 * Create an `AudioDestinationNode`
 */
export const createAudioDestinationGraphNode = () => {
    return createBaseAudioNode('destination', context => {
        const node = context.destination;
        return [node, node];
    });
};

/**
 * Create a `DelayNode`
 *
 * @param options - @see DelayOptions
 */
export const createDelayGraphNode = (options?: DelayOptions) => {
    return createBaseAudioNode('delay', context => {
        const node = createDelayNode(context, options);
        return [node, node];
    });
};

/**
 * Create a ChannelSplitterNode
 *
 * @param options - @see ChannelSplitterOptions
 */
export const createChannelSplitterGraphNode = (
    options?: ChannelSplitterOptions,
) => {
    return createBaseAudioNode('splitter', context => {
        const node = createChannelSplitterNode(context, options);
        return [node, node];
    });
};

/**
 * Create a ChannelMergerNode
 *
 * @param options - @see ChannelMergerOptions
 */
export const createChannelMergerGraphNode = (
    options?: ChannelMergerOptions,
) => {
    return createBaseAudioNode('merger', context => {
        const node = createChannelMergerNode(context, options);
        return [node, node];
    });
};

interface AudioGraphProps {
    closing: boolean;
    inits: Set<AudioNodeInit>;
    workletModule?: WorkletModule;
}

const createNodeInitDisconnector =
    () =>
    (
        srcInit: AudioNodeInitConnectParam | undefined,
        destInit: AudioNodeInitConnectParam | undefined,
    ) => {
        if (!srcInit) {
            return;
        }
        const [source, outputIdx, inputIdx] = Array.isArray(srcInit)
            ? srcInit
            : [srcInit];
        if (!source) {
            return;
        }
        if (isAudioParam(source)) {
            throw new Error(
                'AudioParam cannot be used as the source of disconnect',
            );
        }
        const [destination] = Array.isArray(destInit) ? destInit : [destInit];
        return source.disconnect([destination, outputIdx, inputIdx]);
    };
const createNodeInitConnector =
    (context: AudioContext) =>
    (
        srcInit: AudioNodeInitConnectParam | undefined,
        destInit: AudioNodeInitConnectParam | undefined,
    ) => {
        if (!srcInit || !destInit) {
            return;
        }
        const [source, outputIdx, inputIdx] = Array.isArray(srcInit)
            ? srcInit
            : [srcInit];
        const [destination] = Array.isArray(destInit) ? destInit : [destInit];
        if (!source || !destination) {
            return;
        }
        if (isAudioParam(source)) {
            throw new Error(
                'AudioParam cannot be used as the source of connect',
            );
        }
        const [sourceNode] = source.audioNode
            ? [source.audioNode]
            : source.create(context);
        if (isAudioNodeInit(destination) && !destination.audioNode) {
            destination.create(context, sourceNode);
        }
        return source.connect([destination, outputIdx, inputIdx]);
    };

/**
 * Accepts AudioNodeInitConnections to build the audio graph within a signal audio context
 *
 * @param initialConnections - A list of AudioNodeInit to build the graph in a linear fashion
 * @param options - @see {@link AudioGraphOptions}
 *
 * @example
 *
 * ```typescript
 * const source = createStreamSourceGraphNode(stream);
 * const analyzer = createAnalyzerGraphNode({fftSize});
 * const audioGraph = createAudioGraph([[source, analyzer]]);
 * ```
 */
export const createAudioGraph = (
    initialConnections: AudioNodeInitConnections,
    options: AudioGraphOptions = {},
): AudioGraph => {
    const props: AudioGraphProps = {
        closing: false,
        inits: new Set(),
    };
    const audioContext =
        options.context ?? createAudioContext(options.contextOptions);

    const unsubscribeStateChanged = resumeAudioOnInterruption(audioContext);
    const connectInit = createNodeInitConnector(audioContext);
    const disconnectInit = createNodeInitDisconnector();

    const connect: AudioGraph['connect'] = sequence => {
        sequence.forEach((initParam, idx, initParams) => {
            const prevParam = initParams[idx - 1];
            const [currentInit] = Array.isArray(initParam)
                ? initParam
                : [initParam];
            connectInit(prevParam, currentInit);
            if (isAudioNodeInit(currentInit)) {
                // Keep a reference
                props.inits.add(currentInit);
            }
        });
    };

    const disconnect: AudioGraph['disconnect'] = sequence => {
        sequence.forEach((initParam, idx, initParams) => {
            const prevParam = initParams[idx - 1];
            const [currentInit] = Array.isArray(initParam)
                ? initParam
                : [initParam];
            disconnectInit(prevParam, currentInit);
        });
    };
    const addWorklet: AudioGraph['addWorklet'] = async (moduleURL, options) => {
        if (!props.workletModule) {
            await audioContext.audioWorklet.addModule(moduleURL, options);
            props.workletModule = {moduleURL, options};
        }
    };

    // Initial connections setup
    initialConnections.forEach(connect);

    const releaseInit: AudioGraph['releaseInit'] = init => {
        if (props.inits.has(init)) {
            init.release();
            props.inits.delete(init);
        }
    };

    return {
        get inits() {
            return Array.from(props.inits);
        },

        get context() {
            return audioContext;
        },

        get state() {
            return props.closing ? 'closing' : audioContext.state;
        },

        connect,
        disconnect,

        addWorklet,

        releaseInit,

        release: () => {
            return new Promise<void>(resolve => {
                if (!props.closing && audioContext.state !== 'closed') {
                    props.closing = true;
                    props.workletModule = undefined;
                    unsubscribeStateChanged();
                    void audioContext.close().then(() => {
                        props.closing = false;
                        props.inits.forEach(releaseInit);
                        resolve();
                    });
                } else {
                    resolve();
                }
            });
        },
    };
};

type AudioGraphProxyHandler = (
    target: AudioGraph,
    args: Parameters<AudioGraph['connect']>,
) => void;
interface AudioGraphProxyHandlers {
    connect?: AudioGraphProxyHandler;
    disconnect?: AudioGraphProxyHandler;
}
export const createAudioGraphProxy = (
    audioGraph: AudioGraph,
    handlers: AudioGraphProxyHandlers,
) =>
    new Proxy(audioGraph, {
        get: (target, p: keyof AudioGraph) => {
            switch (p) {
                case 'connect': {
                    const r = target[p];
                    return (...args: Parameters<AudioGraph['connect']>) => {
                        handlers.connect?.(target, args);
                        return r.apply(target, args);
                    };
                }
                case 'disconnect': {
                    const r = target[p];
                    return (...args: Parameters<AudioGraph['disconnect']>) => {
                        handlers.disconnect?.(target, args);
                        return r.apply(target, args);
                    };
                }
                default: {
                    return target[p];
                }
            }
        },
        set: () => false,
    });
