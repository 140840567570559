export const AUDIO_OGG_OPUS = 'audio/ogg; codecs=opus';
export const AUDIO_WEBM_OPUS = 'audio/webm; codecs=opus';
export const AUDIO_MP4 = 'audio/mp4';

export const getAudioMimeType = () =>
    [AUDIO_WEBM_OPUS, AUDIO_OGG_OPUS, AUDIO_MP4].find(mimeType =>
        MediaRecorder.isTypeSupported(mimeType),
    ) ?? AUDIO_OGG_OPUS;

export interface MediaStore {
    add(blob: Blob): void;
    clear(): void;
    get(): Blob[];
}

export interface RecorderEvents {
    onDataAvailable?: (data: Blob) => void;
    onPause?: () => void;
    onStart?: () => void;
    onStop?: () => void;
    onResume?: () => void;
    onError?: () => void;
}
export interface Recorder
    extends RecorderEvents,
        Pick<
            MediaRecorder,
            | 'state'
            | 'mimeType'
            | 'stream'
            | 'audioBitsPerSecond'
            | 'videoBitsPerSecond'
            | 'start'
            | 'stop'
            | 'requestData'
            | 'pause'
            | 'resume'
        > {
    clean(): void;
}

export const createMediaRecorder = (
    tracks: MediaStreamTrack[],
    mimeType: string,
    store?: Pick<MediaStore, 'add'>,
): Recorder => {
    const kinds = ['audio', 'video'].flatMap(kind => {
        if (mimeType.includes('video')) {
            return [kind];
        }
        if (mimeType.includes(kind)) {
            return [kind];
        }
        return [];
    });
    if (!tracks.some(track => kinds.includes(track.kind))) {
        throw new Error('No valid track');
    }

    const eventHandlers: RecorderEvents = {};

    const recorder = new MediaRecorder(
        new MediaStream(
            tracks.flatMap(track => {
                if (kinds.includes(track.kind)) {
                    const cloned = track.clone();
                    cloned.enabled = true;
                    return cloned;
                }
                return [];
            }),
        ),
        {mimeType},
    );

    recorder.ondataavailable = ev => {
        eventHandlers.onDataAvailable?.(ev.data);
        store?.add(ev.data);
    };
    recorder.onstop = () => {
        eventHandlers.onStop?.();
    };
    recorder.onstart = () => {
        eventHandlers.onStart?.();
    };
    recorder.onresume = () => {
        eventHandlers.onResume?.();
    };
    recorder.onpause = () => {
        eventHandlers.onPause?.();
    };
    recorder.onerror = () => {
        eventHandlers.onError?.();
    };

    return {
        get stream() {
            return recorder.stream;
        },
        get mimeType() {
            return recorder.mimeType;
        },
        get state() {
            return recorder.state;
        },
        get audioBitsPerSecond() {
            return recorder.audioBitsPerSecond;
        },
        get videoBitsPerSecond() {
            return recorder.videoBitsPerSecond;
        },
        set onDataAvailable(handler: RecorderEvents['onDataAvailable']) {
            eventHandlers.onDataAvailable = handler;
        },
        set onStart(handler: RecorderEvents['onStart']) {
            eventHandlers.onStart = handler;
        },
        set onStop(handler: RecorderEvents['onStop']) {
            eventHandlers.onStop = handler;
        },
        set onPause(handler: RecorderEvents['onPause']) {
            eventHandlers.onPause = handler;
        },
        set onResume(handler: RecorderEvents['onResume']) {
            eventHandlers.onResume = handler;
        },
        set onError(handler: RecorderEvents['onError']) {
            eventHandlers.onError = handler;
        },
        start(timeslice) {
            recorder.start(timeslice);
        },
        stop() {
            recorder.stop();
        },
        pause() {
            recorder.pause();
        },
        resume() {
            recorder.resume();
        },
        requestData() {
            recorder.requestData();
        },
        clean() {
            for (const track of recorder.stream.getTracks()) {
                track.stop();
            }
        },
    };
};

export const createInMemoryMediaSourceStore = (): MediaStore => {
    let chunks: Blob[] = [];

    return {
        add: blob => {
            chunks.push(blob);
        },
        clear: () => {
            chunks = [];
        },
        get: () => {
            return chunks;
        },
    };
};
