import type {Channel, EventMessage, Event} from '@pexip/plugin-api';

import {callSignals} from '../../signals/Call.signals';
import {infinityClientSignals} from '../../signals/InfinityClient.signals';

export const registerInfinityClientSignals = (
    channels: Map<string, Channel>,
) => {
    const forwardEvent = <T extends keyof EventMessage>(
        event: Omit<Event<T>, 'chanId'>,
    ) => {
        for (const channel of channels.values()) {
            channel.sendEvent(event);
        }
    };

    return [
        infinityClientSignals.onParticipants.add(payload => {
            forwardEvent({event: 'event:participants', payload});
        }),
        infinityClientSignals.onParticipantLeft.add(payload => {
            forwardEvent({event: 'event:participantLeft', payload});
        }),
        infinityClientSignals.onParticipantJoined.add(payload => {
            forwardEvent({
                event: 'event:participantJoined',
                payload,
            });
        }),
        infinityClientSignals.onRaiseHand.add(payload => {
            forwardEvent({
                event: 'event:raiseHand',
                payload,
            });
        }),
        infinityClientSignals.onConferenceStatus.add(payload => {
            forwardEvent({
                event: 'event:conferenceStatus',
                payload,
            });
        }),
        infinityClientSignals.onAuthenticatedWithConference.add(context => {
            forwardEvent({
                event: 'event:conference:authenticated',
                payload: context,
            });
        }),
        infinityClientSignals.onConnected.add(connected => {
            forwardEvent({
                event: 'event:connected',
                payload: connected,
            });
        }),
        infinityClientSignals.onDisconnected.add(disconnected => {
            forwardEvent({
                event: 'event:disconnected',
                payload: disconnected,
            });
        }),
        infinityClientSignals.onMe.add(payload => {
            forwardEvent({
                event: 'event:me',
                payload,
            });
        }),
        infinityClientSignals.onMessage.add(msg => {
            const {direct, ...payload} = msg;
            forwardEvent({
                event: direct ? 'event:directMessage' : 'event:message',
                payload,
            });
        }),
        infinityClientSignals.onApplicationMessage.add(applicationMessage => {
            forwardEvent({
                event: 'event:applicationMessage',
                payload: applicationMessage,
            });
        }),
        infinityClientSignals.onTransfer.add(({alias}) => {
            forwardEvent({
                event: 'event:transfer',
                payload: {alias},
            });
        }),
        infinityClientSignals.onCancelTransfer.add(cancelTransfer => {
            forwardEvent({
                event: 'event:cancelTransfer',
                payload: cancelTransfer,
            });
        }),
        infinityClientSignals.onStage.add(stage => {
            forwardEvent({
                event: 'event:stage',
                payload: stage,
            });
        }),
        callSignals.onPresentationConnectionChange.add(state => {
            forwardEvent({
                event: 'event:presentationConnectionStateChange',
                payload: state,
            });
        }),
        infinityClientSignals.onLayoutUpdate.add(layout => {
            forwardEvent({
                event: 'event:layoutUpdate',
                payload: layout,
            });
        }),
        infinityClientSignals.onBreakoutBegin.add(breakout => {
            forwardEvent({
                event: 'event:breakoutBegin',
                payload: breakout,
            });
        }),
        infinityClientSignals.onBreakoutEnd.add(breakout => {
            forwardEvent({
                event: 'event:breakoutEnd',
                payload: breakout,
            });
        }),
        infinityClientSignals.onBreakoutRefer.add(breakout => {
            forwardEvent({
                event: 'event:breakoutRefer',
                payload: breakout,
            });
        }),
    ];
};
