import {useLayoutEffect, useState} from 'react';

import type {Signal} from '@pexip/signal';
import {hasOwn} from '@pexip/utils';

import type {CustomStepConfig} from '../branding';
import {logger} from '../logger';

import {useDirectJoin} from './useDirectJoin';

const isSourceValid = (sources: Record<string, string> | undefined) => {
    if (!sources) {
        return false;
    }

    // Check if default path is provided
    const defaultExists = hasOwn(sources, 'default');

    if (!defaultExists) {
        logger.warn(
            'Custom step is deactivated: Custom Step source must contain at least a default path".',
        );
        return false;
    }

    // Check if sub path is provided
    const isExternalResource = Object.values(sources).some(
        source =>
            typeof source === 'string' &&
            new URL(source, document.baseURI).origin !==
                document.location.origin,
    );

    if (isExternalResource) {
        logger.warn(
            'Custom Step is deactivated: No external sources are allowed for Custom Step.',
        );
        return false;
    }

    // Check if sub path is provided
    const isPathValid = Object.values(sources).some(
        source => typeof source === 'string' && !source.startsWith('./'),
    );

    if (isPathValid) {
        logger.warn(
            'Custom Step is deactivated: All Custom Step sources must be relative to branding path and must start with "./',
        );
        return false;
    }

    // Assume that sources are valid
    return true;
};

export const getShouldRenderCustomStepPageHook =
    (
        renderCustomStepPageSignal: Signal<boolean>,
        customStepConfig: CustomStepConfig | undefined,
    ) =>
    (): boolean => {
        const [shouldRenderCustomStepPage, setShouldRenderCustomStepPage] =
            useState(true);

        const isDirectJoin = useDirectJoin();

        // TODO: This should be substituted by useSyncExternalStore pattern see https://gitlab.com/pexip/zoo/-/issues/4397
        useLayoutEffect(() => {
            renderCustomStepPageSignal.add(shouldRender => {
                setShouldRenderCustomStepPage(shouldRender);
            });
        }, [renderCustomStepPageSignal]);

        return (
            (isSourceValid(customStepConfig?.source) &&
                customStepConfig?.active &&
                shouldRenderCustomStepPage &&
                (!isDirectJoin ||
                    (isDirectJoin && customStepConfig?.mandatory))) ??
            false
        );
    };
