import type {
    Bandwidth,
    DataChannelConfig,
    EventHandler,
    ExtendedRTCPeerConnection,
    MainPeerConnection,
    MainPeerConnectionOptions,
    OnDataChannelEventHandler,
    OnIceCandidateHandler,
    OnNegotiationNeededHandler,
    OnRemoteStreamsEventHandler,
    OnSecureCheckCodeHandler,
    OnTrackEventHandler,
    OnTransceiverChangeHandler,
    PCSignals,
    PeerConnection,
    PeerConnectionOptions,
    PexipMediaLine,
    RTCPeerConnectionEventListeners,
    References,
    TransceiverConfig,
} from './types';
import {RecoveryTimeout} from './types';
import {
    SdpTransformManager,
    getMediaLines,
    hasICECandidates,
} from './sdpManager';
import type {SdpOptions, Fingerprint} from './sdpManager';
import {CAN_SET_STREAMS} from './constants';
import {
    changeTransceiverDirection,
    createEventQueue,
    createGetRefs,
    createMediaConfigs,
    createRTCPeerConnection,
    createRefsLog,
    getPeerConnectionStates,
    getRelativeDirection,
    isDataChannelConfig,
    isTransceiverConfig,
    isTransceiverObsolete,
    logReferences,
    withSignals,
} from './utils';

type SessionDescriptionInitProp = undefined | RTCSessionDescriptionInit;

interface AssociateStreamOptions {
    transceiver: RTCRtpTransceiver;
    stream: MediaStream;
    sdp: RTCSessionDescriptionInit | SdpTransformManager;
    sdpOptions?: SdpOptions;
}

const MAX_M_CONTENT_COUNT = 10;
const MAX_DEFER_NEGOTIATION_COUNT = 10;

/**
 * Wrap RTCPeerConnection with polyfill the old APIs and simplifies the common logics
 *
 * @param options - Configuration for the peer connection
 * @param peerConnection - Inject your own RTCPeerConnection, can be used for
 * testing
 */
export function createPeerConnection(
    options: PeerConnectionOptions = {},
    peerConnection?: ExtendedRTCPeerConnection,
): PeerConnection {
    const props = {
        bandwidth: options.bandwidth ?? {in: 0, out: 0},
        offerOptions: options.offerOptions,
        answerOptions: options.answerOptions,
        references: {
            module: 'PeerConnection',
            createdAt: new Date().toISOString(),
        } as References,
        allowCodecSdpMunging: Boolean(options.allowCodecSdpMunging),
        // Only used when `restartIce` is not available
        iceRestartNeeded: false,
        initialConnectionDone: false,
        makingOffer: false,
        makingAnswer: false,
        ignoreNegotiationNeeded: false,
        pendingOffer: undefined as SessionDescriptionInitProp,
        allowVP9: options.allowVP9 ?? true,
        allow1080p: options.allow1080p,
        allow4kPreso: options.allow4kPreso,
        currentRemoteDescription: undefined as SessionDescriptionInitProp,
        localFingerprints: [] as Fingerprint[],
        remoteFingerprints: [] as Fingerprint[],
        mediaContentMismatchCount: 0,
        deferNegotiationCount: 0,
        polite: !!options.polite,
    };

    const mediaConfigs = createMediaConfigs(options.mediaInits, () =>
        eventHandlers.onTransceiverChange?.(),
    );

    let remoteMediaLines = new Map<string, PexipMediaLine>();

    const timers: {
        iceConnectionState?: number;
        connectionState?: number;
        negotiation?: number;
    } = {};

    const clearTimer = (timerKey: keyof typeof timers, onDone?: () => void) => {
        if (timers[timerKey]) {
            clearTimeout(timers[timerKey]);
            timers[timerKey] = undefined;
            onDone?.();
        }
    };

    const eventHandlers: {
        negotiationNeeded?: OnNegotiationNeededHandler;
        iceConnectionStateChange?: EventHandler;
        signalingStateChange?: EventHandler;
        onTrack?: OnTrackEventHandler;
        onRemoteStreams?: OnRemoteStreamsEventHandler;
        onIceCandidate?: OnIceCandidateHandler;
        onTransceiverChange?: OnTransceiverChangeHandler;
        onSecureCheckCode?: OnSecureCheckCodeHandler;
        onDataChannel?: OnDataChannelEventHandler;
    } = {};

    const localICECandidateQueue = createEventQueue(
        (evt: RTCPeerConnectionIceEvent) => {
            eventHandlers.onIceCandidate?.(evt);
        },
    );

    const pcEventListeners = [
        {
            event: 'iceconnectionstatechange',
            listener: event => {
                log.info('handle iceconnectionstatechange event', {event});
                const pc = event.currentTarget as RTCPeerConnection;
                clearTimer('iceConnectionState', () => {
                    log.debug('clears the restart ice timer', {event});
                });
                if (pc.iceConnectionState === 'failed') {
                    // FIXME: Should update configuration before restarting ICE
                    log.debug('restarts ice because it went to failed', {
                        event,
                    });
                    restartIce();
                } else if (pc.iceConnectionState === 'disconnected') {
                    log.debug(
                        'schedule ice restart because it went to disconnected',
                        {
                            event,
                        },
                    );
                    timers.iceConnectionState = window.setTimeout(
                        restartIce,
                        RecoveryTimeout.IceConnectionState,
                    );
                }

                if (eventHandlers.iceConnectionStateChange) {
                    eventHandlers.iceConnectionStateChange(event);
                }
            },
        },
        {
            event: 'signalingstatechange',
            listener: event => {
                log.info('handle signalingstatechange event', {event});
                const pc = event.currentTarget as RTCPeerConnection;
                if (pc.signalingState === 'stable') {
                    if (
                        props.iceRestartNeeded &&
                        eventHandlers.onIceCandidate
                    ) {
                        fallbackRestartIce();
                    }
                }

                if (eventHandlers.signalingStateChange) {
                    eventHandlers.signalingStateChange(event);
                }
            },
        },
        {
            event: 'icecandidate',
            listener: event => {
                if (!eventHandlers.onIceCandidate) {
                    return;
                }
                if (
                    props.makingAnswer ||
                    props.makingOffer ||
                    localICECandidateQueue.buffering
                ) {
                    log.debug('buffering ICE candidate', {
                        event,
                        makingOffer: props.makingOffer,
                        makingAnswer: props.makingAnswer,
                        localICECandidateBuffering:
                            localICECandidateQueue.buffering,
                    });
                    localICECandidateQueue.buffering = true;
                }
                localICECandidateQueue.enqueue(event);
            },
        },
        {
            event: 'negotiationneeded',
            listener: () => {
                log.info('handle negotiationneeded event', {eventHandlers});
                if (eventHandlers.negotiationNeeded) {
                    negotiate();
                }
            },
        },
        {
            event: 'track',
            listener: event => {
                log.info('handle track event', {
                    event,
                    remoteMediaLines,
                    mediaConfigs,
                });
                const config =
                    mediaConfigs.getConfig(event.transceiver) ??
                    mediaConfigs.find(config => {
                        if (!isTransceiverConfig(config)) {
                            return false;
                        }
                        const media = remoteMediaLines.get(
                            event.transceiver.mid ?? '',
                        );
                        const content = media?.content ?? 'main';
                        const kind = media?.type;
                        return (
                            kind === config.kind && content === config.content
                        );
                    });
                if (!isTransceiverConfig(config)) {
                    log.warn('Unable to find respective transceiver config', {
                        mediaConfigs,
                        event,
                    });
                    eventHandlers.onTrack?.(event);
                    return;
                }
                config.remoteStreams = event.streams.length
                    ? event.streams
                    : [new MediaStream([event.track])];
                eventHandlers.onRemoteStreams?.(config);
                eventHandlers.onTrack?.(event);
            },
        },
        {
            event: 'datachannel',
            listener: event => {
                log.info('handle datachannel event', {
                    event,
                    eventHandler: eventHandlers.onDataChannel,
                });
                const config = mediaConfigs.find(
                    config =>
                        isDataChannelConfig(config) && !config.dataChannel,
                );
                if (isDataChannelConfig(config)) {
                    config.dataChannel = event.channel;
                }
                eventHandlers.onDataChannel?.(event);
            },
        },
    ] satisfies RTCPeerConnectionEventListeners;
    const peer = createRTCPeerConnection(
        options.rtcConfig,
        pcEventListeners,
        peerConnection,
    );

    // HACK: Fix for odd Firefox behavior, see: https://github.com/feross/simple-peer/pull/783
    if (typeof peer.peerIdentity === 'object') {
        peer.peerIdentity.catch((error: unknown) => {
            log.error('peerIdentity error', {error});
            peer.close();
        });
    }

    const log = createRefsLog(() => ({
        ...logReferences(props.references),
        ...getPeerConnectionStates(peer),
        props,
    }));

    /**
     * `RTCRtpSender['setStreams']` fallback function with polyfill when the API is not available
     * by using SDP mangling
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/RTCRtpSender/setStreams}
     */
    const associateStream = ({
        transceiver,
        stream,
        sdp,
        sdpOptions,
    }: AssociateStreamOptions): void => {
        const sdpManager =
            sdp instanceof SdpTransformManager
                ? sdp
                : new SdpTransformManager(sdp, sdpOptions);
        sdpManager.addMsidToMline(transceiver, stream.id);
    };

    /**
     * Polyfill to browsers without RTCRtpSender['setStreams'] with SDP Mangling
     * */
    const associateStreamWithSDP = (
        {streams, transceiver}: TransceiverConfig,
        sdp: RTCSessionDescriptionInit | SdpTransformManager,
        sdpOptions?: SdpOptions,
    ) => {
        const [stream] = streams;
        if (!stream || !transceiver) {
            return;
        }

        log.debug('Manual associate stream with SDP', {
            sdp: sdp,
            options: sdpOptions,
            stream,
            currentLocalDescription: peer.currentLocalDescription,
            transceivers: peer.getTransceivers(),
            transceiver,
        });
        associateStream({
            transceiver,
            stream,
            sdp,
            sdpOptions,
        });
    };

    /**
     * Set the local `MediaStream`, and replace the tracks when there is already
     * one in the process
     *
     * @param mediaStream - the media stream to add
     * @param targets - A list a target `TransceiverConfig` to update the stream
     */
    const setLocalStream: PeerConnection['setLocalStream'] = async (
        mediaStream: MediaStream | undefined,
        targets,
        shouldSyncMedia = true,
    ) => {
        log.info('call setLocalStream', {mediaStream, targets});
        const streams = mediaStream ? [mediaStream] : [];
        for (const [config, intentDirection] of targets) {
            const direction = changeTransceiverDirection(
                config.transceiver?.currentDirection ?? config.direction,
                intentDirection,
            );
            const track =
                mediaStream
                    ?.getTracks()
                    .find(
                        track =>
                            track.kind === config.kind &&
                            track.readyState === 'live',
                    ) ?? null;
            config.track = track;
            config.streams = streams;
            config.direction = direction;
        }
        if (shouldSyncMedia) {
            await syncMedia(remoteMediaLines, true);
        }
    };

    /**
     * Trigger manual negotiationNeeded event
     */
    const negotiate = () => {
        clearTimer('negotiation');
        if (props.mediaContentMismatchCount >= MAX_M_CONTENT_COUNT) {
            log.info(
                'Failed to negotiate with remote peer because of transceiver content attribute mismatch',
            );
            props.mediaContentMismatchCount = 0;
            return;
        }
        if (props.deferNegotiationCount >= MAX_DEFER_NEGOTIATION_COUNT) {
            log.info('Defer negotiation is more than allowed');
            props.deferNegotiationCount = 0;
            return;
        }
        if (eventHandlers.negotiationNeeded) {
            if (
                !props.ignoreNegotiationNeeded &&
                !props.makingOffer &&
                peer.signalingState === 'stable'
            ) {
                log.info('call negotiationneeded handler');
                props.deferNegotiationCount = 0;
                eventHandlers.negotiationNeeded();
            } else {
                log.info('defer negotiation', {
                    ignoreNegotiationNeeded: props.ignoreNegotiationNeeded,
                    makingOffer: props.makingOffer,
                    signalingState: peer.signalingState,
                });
                props.deferNegotiationCount += 1;
                timers.negotiation = window.setTimeout(
                    negotiate,
                    RecoveryTimeout.Negotiation,
                );
            }
        } else {
            log.warn(
                '[negotiate] try to negotiate but no negotiationneeded handler',
            );
        }
    };

    /**
     * Trigger iceRestart manual mode
     */
    const fallbackRestartIce = () => {
        log.info('[restartIce fallback] trigger negotiationneeded manually');
        props.offerOptions = {...props.offerOptions, iceRestart: true};
        props.iceRestartNeeded = false;
        negotiate();
    };

    /**
     * The WebRTC API's `RTCPeerConnection` interface offers the `restartIce()`
     * method to allow a web application to easily request that ICE candidate
     * gathering be redone on both ends of the connection. This simplifies the
     * process by allowing the same method to be used by either the caller or
     * the receiver to trigger an ICE restart.
     *
     * This function includes the logic to check if the latest API is available,
     * and use the fallback, `RTCPeerConnection['createOffer']` with
     * `iceRestart` set to `true`.
     */
    const restartIce = () => {
        if (!eventHandlers.onIceCandidate) {
            // Don't support Vanilla ICE Restart
            return;
        }
        clearTimer('connectionState', () => {
            log.debug('clears connectionStateTimer due to iceRestart');
        });
        if (peer.restartIce !== undefined) {
            log.info('restartIce');
            peer.restartIce();
        } else {
            // Fallback restartIce to a manual mode
            if (peer.signalingState === 'stable') {
                log.info('restartIce fallback');
                fallbackRestartIce();
            } else {
                // Wait for `signalingState` changes to `stable` to restartIce
                props.iceRestartNeeded = true;
                log.info('restartIce fallback deferred');
            }
        }
    };

    /**
     * Calculate the secure check code from the local and remote fingerprints
     */
    const calculateSecureCheckCode = async () => {
        log.info('calculateSecureCheckCode');
        if (props.localFingerprints.length && props.remoteFingerprints.length) {
            // Sort to ensure deterministic behaviour
            const local = props.localFingerprints
                .map(({type, hash}) => type + hash)
                .sort()
                .join('');
            const remote = props.remoteFingerprints
                .map(({type, hash}) => type + hash)
                .sort()
                .join('');
            const msg = [local, remote].sort().join('');

            // SHA-256 hash the result
            const msgBuffer = new TextEncoder().encode(msg);
            const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
            const hashArray = Array.from(new Uint8Array(hashBuffer));
            const hashHex = hashArray
                .map(b => b.toString(16).padStart(2, '0'))
                .join('');

            eventHandlers.onSecureCheckCode?.(hashHex);
        }
    };

    /**
     * Two-way sync of transceivers & data channel between `PeerConnection` and `mediaConfigs`
     * Should only be called after the offer/answer is applied
     *
     * @param remoteMediaLines - The remote media lines
     * @param active - Indicate it is an active sync, e.g. creating an offer
     *
     * @internal
     */
    const syncMedia = async (
        remoteMediaLines: Map<string, PexipMediaLine>,
        active: boolean,
    ) => {
        if (peer.connectionState === 'closed') {
            return;
        }
        const currentTransceivers = peer.getTransceivers();
        log.info('Sync Media', {
            currentTransceivers,
            mediaConfigs,
            remoteMediaLines,
            active,
        });
        try {
            props.ignoreNegotiationNeeded = true;
            // PeerConnection's transceivers => MediaConfigs
            const transceivers = new Map(
                currentTransceivers.flatMap(trans =>
                    isTransceiverObsolete(trans) ? [] : [[trans.mid, trans]],
                ),
            );
            // Matching the remote SDP's media descriptions
            for (const remoteMedia of remoteMediaLines.values()) {
                const remoteContent = remoteMedia.content ?? 'main';
                const mid = String(remoteMedia.mid);
                const relativeDirection = active
                    ? undefined
                    : getRelativeDirection(remoteMedia.direction);
                switch (remoteMedia.type) {
                    case 'application': {
                        // Look for the data channel with respective ID
                        const config = mediaConfigs.find(
                            config =>
                                isDataChannelConfig(config) &&
                                !!config.options.negotiated &&
                                String(config.options.id) === mid,
                        ) as DataChannelConfig | undefined;
                        if (config) {
                            log.debug(
                                `Sync existing ${config} data channel config`,
                                {
                                    remoteMedia,
                                    config,
                                    mediaConfigs,
                                },
                            );
                            config.syncDataChannel(peer);
                        }
                        break;
                    }
                    case 'audio':
                    case 'video': {
                        const transceiver = transceivers.get(mid);
                        if (!transceiver) {
                            throw new Error(
                                `Cannot find respective Transceiver with mid:${mid}`,
                            );
                        }
                        const existingConfig =
                            mediaConfigs.getConfig(transceiver);
                        if (existingConfig) {
                            log.debug(
                                `Sync existing ${existingConfig} transceiver config`,
                                {
                                    transceiver,
                                    remoteMedia,
                                    mediaConfigs,
                                },
                            );
                            if (existingConfig.content !== remoteContent) {
                                log.warn(
                                    `(local:content) ${existingConfig.content} <==> (remote:content) ${remoteMedia.content}`,
                                    {remoteMedia, localConfig: existingConfig},
                                );
                                props.mediaContentMismatchCount++;
                                continue;
                            }
                            await existingConfig.syncTransceiver(peer, {
                                direction: existingConfig.relativeDirection
                                    ? relativeDirection
                                    : undefined,
                            });
                        } else {
                            const matchedConfig = mediaConfigs.find(config => {
                                // Get the first matching config
                                return (
                                    isTransceiverConfig(config) &&
                                    (!config.transceiver ||
                                        isTransceiverObsolete(
                                            config.transceiver,
                                        )) &&
                                    // Assume it is `main` when no content attribute is set
                                    config.content === remoteContent &&
                                    config.kind === remoteMedia.type
                                );
                            }) as TransceiverConfig | undefined;
                            if (matchedConfig) {
                                log.debug(
                                    `found matched ${matchedConfig} transceiver config`,
                                    {
                                        transceiver,
                                        matchedConfig,
                                        remoteMedia,
                                    },
                                );
                                matchedConfig.transceiver = transceiver;
                                await matchedConfig.syncTransceiver(peer, {
                                    direction: matchedConfig.relativeDirection
                                        ? relativeDirection
                                        : undefined,
                                });
                            } else {
                                log.debug(
                                    'Transceiver config mismatched, adding a new config',
                                    {transceiver, remoteMedia, mediaConfigs},
                                );
                                const newConfig = mediaConfigs.addConfig(peer, {
                                    kindOrTrack:
                                        transceiver.sender.track ??
                                        remoteMedia.type,
                                    content: remoteMedia.content ?? 'main',
                                    transceiver,
                                    direction: relativeDirection,
                                });
                                await newConfig.syncTransceiver(peer);
                            }
                        }
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
            // According to RFC3264#Section 6:
            // For each "m=" line in the offer, there MUST be a corresponding "m="
            // line in the answer.  The answer MUST contain exactly the same number
            // of "m=" lines as the offer.
            // Only Sync media based on the configs when it is Offer aka `active===true`
            if (!active) {
                return;
            }
            for (const config of mediaConfigs.configs) {
                if (isTransceiverConfig(config)) {
                    await config.syncTransceiver(peer);
                }
                if (isDataChannelConfig(config)) {
                    config.syncDataChannel(peer);
                }
            }
        } finally {
            props.ignoreNegotiationNeeded = false;
            props.initialConnectionDone = true;
        }
    };

    const createOffer: PeerConnection['createOffer'] = async offerOptions => {
        log.info('call createOffer', {param: offerOptions, peer});
        props.makingOffer = true;
        try {
            const sdp = await peer.createOffer({
                ...props.offerOptions,
                ...offerOptions,
            });
            log.debug('created raw Offer', {sdp});
            const sdpManager = new SdpTransformManager(sdp, {
                allow1080p: props.allow1080p,
                allow4kPreso: props.allow4kPreso,
                allowCodecSdpMunging: props.allowCodecSdpMunging,
                allowVP9: props.allowVP9,
                contents: mediaConfigs.configs.flatMap(config =>
                    isTransceiverConfig(config) ? [config.content] : [],
                ),
                sendEncodings: mediaConfigs.configs.flatMap(config =>
                    isTransceiverConfig(config)
                        ? [config.sendEncodings ?? []]
                        : [],
                ),
                videoAS: props.bandwidth.in,
            });
            let offer = sdpManager.getSdp();
            if (peer.signalingState !== 'stable') {
                throw new Error('Ignore');
            }
            await peer.setLocalDescription(offer);
            if (!CAN_SET_STREAMS) {
                mediaConfigs.configs.forEach(config => {
                    if (config.kind === 'video' || config.kind === 'audio') {
                        associateStreamWithSDP(config, sdpManager);
                    }
                });
            }
            offer = sdpManager.getSdp();
            log.info('setLocalDescription with offer success', {
                rawSDP: sdp,
                offer,
            });
            props.localFingerprints = sdpManager.getFingerprints();
            await calculateSecureCheckCode();
            return offer;
        } finally {
            props.makingOffer = false;
        }
    };

    return {
        get peer() {
            return peer;
        },

        get polite() {
            return props.polite;
        },

        get iceGatheringState() {
            return peer.iceGatheringState;
        },

        get iceConnectionState() {
            return peer.iceConnectionState;
        },

        get signalingState() {
            return peer.signalingState;
        },

        get connectionState() {
            return peer.connectionState;
        },

        get senders() {
            return peer.getSenders();
        },

        get receivers() {
            return peer.getReceivers();
        },

        get configs() {
            return mediaConfigs.configs;
        },

        getTransceiverConfigs: () => {
            return mediaConfigs.configs.filter(isTransceiverConfig);
        },

        getDataChannelConfigs: () => {
            return mediaConfigs.configs.filter(isDataChannelConfig);
        },

        addConfig: mediaConfigs.addConfig,

        releaseLocalICECandidatesBuffer: ignore => {
            if (!eventHandlers.onIceCandidate) {
                return;
            }
            localICECandidateQueue.buffering = false;
            if (!ignore && localICECandidateQueue.length) {
                const eventFlushed = localICECandidateQueue.flush();
                log.debug('Release buffered local ICE candidates', {
                    events: eventFlushed,
                });
                return;
            }
            localICECandidateQueue.discard();
        },

        get hasICECandidates() {
            return hasICECandidates(
                peer.pendingLocalDescription?.sdp ??
                    peer.currentLocalDescription?.sdp,
            );
        },

        get currentLocalDescription() {
            if (peer.currentLocalDescription !== undefined) {
                return peer.currentLocalDescription;
            }
            return peer.localDescription;
        },

        get pendingLocalDescription() {
            if (peer.pendingLocalDescription !== undefined) {
                return peer.pendingLocalDescription ?? undefined;
            }
            return peer.signalingState === 'stable' ||
                peer.localDescription === null
                ? undefined
                : peer.localDescription;
        },

        get currentRemoteDescription() {
            if (peer.currentRemoteDescription !== undefined) {
                return peer.currentRemoteDescription;
            }
            return peer.remoteDescription;
        },

        get pendingRemoteDescription() {
            if (peer.pendingRemoteDescription !== undefined) {
                return peer.pendingRemoteDescription;
            }
            return peer.signalingState === 'stable'
                ? null
                : peer.remoteDescription;
        },

        get bandwidth() {
            return props.bandwidth;
        },

        get references() {
            return props.references;
        },

        get offerOptions() {
            return props.offerOptions;
        },

        set offerOptions(newOptions) {
            props.offerOptions = {...props.offerOptions, ...newOptions};
        },

        get answerOptions() {
            return props.answerOptions;
        },

        set answerOptions(newOptions) {
            log.info('set answerOptions', {
                newOptions,
                answerOptions: props.answerOptions,
            });
            props.answerOptions = {...props.answerOptions, ...newOptions};
        },

        set bandwidth(bandwidth) {
            log.info('set bandwidth', {
                newBandwidth: bandwidth,
                bandwidth: props.bandwidth,
            });
            if (
                props.bandwidth.in !== bandwidth.in ||
                props.bandwidth.out !== bandwidth.out
            ) {
                props.bandwidth = bandwidth;
                negotiate();
            }
        },

        set negotiationNeeded(needed: boolean) {
            if (needed) {
                negotiate();
            }
        },

        negotiate: async () => {
            return syncMedia(remoteMediaLines, true);
        },

        getStats: async selector => await peer.getStats(selector),

        setLocalStream,

        setReference(key, value) {
            log.info('call setReference', {
                references: props.references,
                key,
                value,
            });
            props.references[key] = value;
        },

        createDataChannel: (label, dataChannelDict) => {
            log.info('call createDataChannel', {label, dataChannelDict});
            return peer.createDataChannel(label, dataChannelDict);
        },

        createOffer,

        createAnswer: async answerOptions => {
            log.info('call createAnswer', {param: answerOptions});
            props.makingAnswer = true;
            try {
                const sdp = await peer.createAnswer({
                    ...props.answerOptions,
                    ...answerOptions,
                });
                log.debug('created raw Answer', {sdp});
                const sdpManager = new SdpTransformManager(sdp, {
                    allow4kPreso: props.allow4kPreso,
                    allowCodecSdpMunging: props.allowCodecSdpMunging,
                    allowVP9: props.allowVP9,
                    videoAS: props.bandwidth.in,
                    videoTIAS: props.bandwidth.in * 1000,
                });
                mediaConfigs.configs.forEach(config => {
                    if (
                        isTransceiverConfig(config) &&
                        config.transceiver?.mid
                    ) {
                        sdpManager.addContentAttribute(
                            config.transceiver.mid,
                            config.content,
                        );
                    }
                });
                let answer = sdpManager.getSdp();
                await peer.setLocalDescription(answer);
                if (!CAN_SET_STREAMS) {
                    mediaConfigs.configs.forEach(config => {
                        if (
                            config.kind === 'video' ||
                            config.kind === 'audio'
                        ) {
                            associateStreamWithSDP(config, sdpManager);
                        }
                    });
                }
                answer = sdpManager.getSdp();
                log.info('setLocalDescription with answer success', {
                    rawSDP: sdp,
                    answer,
                });

                props.localFingerprints = sdpManager.getFingerprints();
                await calculateSecureCheckCode();

                return answer;
            } finally {
                props.makingAnswer = false;
            }
        },

        receiveAnswer: async rawAnswer => {
            log.info('call receiveAnswer', {rawAnswer});
            remoteMediaLines = new Map(
                getMediaLines(rawAnswer.sdp).map(mline => [
                    String(mline.mid),
                    mline,
                ]),
            );

            const sdpManager = new SdpTransformManager(rawAnswer, {
                allowCodecSdpMunging: props.allowCodecSdpMunging,
                allowVP9: props.allowVP9,
                videoAS: props.bandwidth.out,
                videoTIAS: props.bandwidth.out * 1000,
            });
            const answer = sdpManager.getSdp();
            await peer.setRemoteDescription(answer);
            log.debug('setRemoteDescription success', {
                currentTransceivers: peer.getTransceivers(),
                mediaConfigs,
                rawAnswer,
                answer,
            });

            props.remoteFingerprints = sdpManager.getFingerprints();
            await calculateSecureCheckCode();
            props.currentRemoteDescription = rawAnswer;
        },

        receiveOffer: async offer => {
            log.info('call receiveOffer', {offer});

            remoteMediaLines = new Map(
                getMediaLines(offer.sdp).map(mline => [
                    String(mline.mid),
                    mline,
                ]),
            );
            props.currentRemoteDescription = offer;

            props.remoteFingerprints = new SdpTransformManager(
                offer,
            ).getFingerprints();
            await peer.setRemoteDescription(offer);
            await syncMedia(remoteMediaLines, false);
            await calculateSecureCheckCode();
        },

        receiveIceCandidate: async candidate => {
            log.info('call receiveIceCandidate', {candidate});
            await peer.addIceCandidate(candidate);
        },

        restartIce,

        getConfiguration: () => peer.getConfiguration(),
        setConfiguration:
            typeof peer.setConfiguration === 'function'
                ? rtcConfig => peer.setConfiguration(rtcConfig)
                : undefined,

        close: () => {
            log.info('call close');
            mediaConfigs.release();
            localICECandidateQueue.discard();
            props.deferNegotiationCount = 0;
            props.mediaContentMismatchCount = 0;
            remoteMediaLines = new Map();
            Object.keys(timers).forEach(key => {
                clearTimer(key as keyof typeof timers);
            });
            peer.close();
        },

        // Event handlers
        set onConnectionStateChange(handler: EventHandler | undefined) {
            peer.onconnectionstatechange = event => {
                clearTimer('connectionState', () => {
                    log.debug('clears the connection state timer', {event});
                });
                if (['disconnected', 'failed'].includes(peer.connectionState)) {
                    log.debug(
                        `waits for emitting connection state change because it is "${peer.connectionState}"`,
                        {event},
                    );
                    timers.connectionState = window.setTimeout(() => {
                        log.debug(
                            'emits connection state change after waiting for ice restart',
                            {event},
                        );
                        handler?.(event);
                    }, RecoveryTimeout.ConnectionState);
                } else {
                    handler?.(event);
                }
            };
        },

        set onDataChannel(handler: OnDataChannelEventHandler) {
            eventHandlers.onDataChannel = handler;
        },

        set onIceCandidate(handler: OnIceCandidateHandler) {
            eventHandlers.onIceCandidate = handler;
        },

        // prettier-ignore
        set onIceCandidateError(
            handler: RTCPeerConnection['onicecandidateerror']
        ) {
            peer.onicecandidateerror = handler;
        },

        set onIceConnectionStateChange(handler: EventHandler | undefined) {
            eventHandlers.iceConnectionStateChange = handler;
        },

        set onIceGatheringStateChange(handler: EventHandler | undefined) {
            peer.onicegatheringstatechange = handler ?? null;
        },

        set onNegotiationNeeded(handler: OnNegotiationNeededHandler) {
            eventHandlers.negotiationNeeded = handler;
        },

        set onSignalingStateChange(handler: EventHandler | undefined) {
            eventHandlers.signalingStateChange = handler;
        },

        set onTransceiverChange(handler: OnTransceiverChangeHandler) {
            eventHandlers.onTransceiverChange = handler;
        },

        set onSecureCheckCode(handler: OnSecureCheckCodeHandler) {
            eventHandlers.onSecureCheckCode = handler;
        },

        set onTrack(handler: OnTrackEventHandler | undefined) {
            eventHandlers.onTrack = handler;
        },

        set onRemoteStreams(handler: OnRemoteStreamsEventHandler | undefined) {
            eventHandlers.onRemoteStreams = handler;
        },
    };
}

/**
 * Logical layer of the Peer Connection for Call/Main, which connecting Signals
 * and `RTCPeerConnection` events, @see PCSignals
 *
 * @param signals - Provide the required signals for communication
 * @param options - Configuration for the the Peer Connection
 */
export function createMainPeerConnection(
    signals: PCSignals,
    options: MainPeerConnectionOptions = {},
): MainPeerConnection {
    const peer = createPeerConnection(options);
    peer.setReference('module', 'MainPeerConnection');
    const {onOfferRequired, ...restSignals} = signals;
    const log = createRefsLog(createGetRefs(peer));

    let subscriptions = [
        // Handler common signals
        ...withSignals(peer)(restSignals),

        onOfferRequired.add(async param => {
            log.info('handle onOfferRequired signal', {param});
            // If peer has local MediaStream, `onnegotiationneeded` event will
            // be triggered when `RTCPeerConnection` is ready.
            // RTCPeerConnection['createOffer'] will be called accordingly by
            // the event.
            if (param?.stream && param.target) {
                try {
                    await peer.setLocalStream(
                        param.stream,
                        param.target,
                        param.syncMedia,
                    );
                } catch (error: unknown) {
                    log.error('setLocalStream failed', {error});
                    peer.negotiationNeeded = true;
                }
            } else {
                await peer.negotiate();
            }
        }),
    ];

    const cleanup = () => {
        subscriptions = subscriptions.flatMap(unsubscribe => {
            unsubscribe();
            return [];
        });
    };

    return {
        get peer() {
            return peer.peer;
        },
        get polite() {
            return peer.polite;
        },
        get connectionState() {
            return peer.connectionState;
        },
        get iceConnectionState() {
            return peer.iceConnectionState;
        },
        get iceGatheringState() {
            return peer.iceGatheringState;
        },
        get signalingState() {
            return peer.signalingState;
        },
        get senders() {
            return peer.senders;
        },

        get receivers() {
            return peer.receivers;
        },

        get hasICECandidates() {
            return peer.hasICECandidates;
        },

        get bandwidth() {
            return peer.bandwidth;
        },

        get references() {
            return peer.references;
        },

        set bandwidth(bandwidth: Bandwidth) {
            peer.bandwidth = bandwidth;
        },

        get offerOptions() {
            return peer.offerOptions;
        },

        get answerOptions() {
            return peer.answerOptions;
        },

        set offerOptions(newOptions) {
            peer.offerOptions = newOptions;
        },

        set answerOptions(newOptions) {
            peer.answerOptions = newOptions;
        },

        get configs() {
            return peer.configs;
        },

        // Methods
        setLocalStream: peer.setLocalStream,

        setReference(key, value) {
            peer.setReference(key, value);
        },

        getStats: selector => peer.getStats(selector),

        getTransceiverConfigs: peer.getTransceiverConfigs,
        getDataChannelConfigs: peer.getDataChannelConfigs,
        addConfig: peer.addConfig,

        createDataChannel: (label, dataChannelDict) =>
            peer.createDataChannel(label, dataChannelDict),

        restartIce: peer.restartIce,

        getConfiguration: peer.getConfiguration,
        setConfiguration: peer.setConfiguration,

        close: () => {
            cleanup();
            peer.close();
        },
    };
}
