import {useConfig} from '../config';
import {BrowserCloseConfirmation} from '../types';

export const useBrowserCloseConfirmationConfig = () => {
    const [browserCloseConfirmation, setBrowserCloseConfirmation] = useConfig(
        'browserCloseConfirmation',
    );

    const [isEnableBrowserCloseConfirmationByDefault] = useConfig(
        'enableBrowserCloseConfirmationByDefault',
    );

    const shouldShowBrowserCloseConfirmation =
        (isEnableBrowserCloseConfirmationByDefault &&
            browserCloseConfirmation === BrowserCloseConfirmation.Unset) ||
        browserCloseConfirmation === BrowserCloseConfirmation.Enabled;

    return {
        browserCloseConfirmation,
        setBrowserCloseConfirmation,
        shouldShowBrowserCloseConfirmation,
        isEnableBrowserCloseConfirmationByDefault,
    };
};
