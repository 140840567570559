interface Translation {
    [key: string]: Translation | string;
}
/**
 * This function makes sure 2 translation objects are deep merged together with
 * the assumption that`t2` will override string keys from `t1`
 * @param t1 - default translation object
 * @param t2 - 2nd translation object that extends default one
 * @returns - `t1` translation object extended by `t2`
 */
export function mergeTranslation(
    t1: Translation,
    t2: Translation,
): Translation {
    for (const [key, value] of Object.entries(t2)) {
        if (!(key in t1)) {
            // merge value from t2 into t1
            t1[key] = value;
        } else if (t1[key] === value) {
            // do nothing
        } else if (typeof t1[key] === 'object' && typeof value === 'object') {
            // not a key/value pair, need to go deeper
            t1[key] = mergeTranslation(t1[key], value);
        } else {
            // override a key
            t1[key] = value;
        }
    }
    return t1;
}
