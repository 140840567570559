import React, {useCallback} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {
    InteractiveElement,
    SvgImage,
    Text,
    ThemeConsumer,
} from '@pexip/components';
import type {ColorScheme} from '@pexip/components';

import {VideoLayoutOptionValues} from '../../types';
import {TestId} from '../../../test/testIds';

import adaptiveComposition from './svgs/AdaptiveComposition.svg';
import speaker7 from './svgs/Speaker7.svg';
import speaker21 from './svgs/Speaker21.svg';
import speaker221 from './svgs/Speaker221.svg';
import speaker33 from './svgs/Speaker33.svg';
import highlight from './svgs/Highlight.svg';
import equal22 from './svgs/Equal22.svg';
import equal33 from './svgs/Equal33.svg';
import equal44 from './svgs/Equal44.svg';
import equal55 from './svgs/Equal55.svg';
import unknown from './svgs/Unknown.svg';

import styles from './LayoutThumbnail.module.scss';

/*
t('settings.layout[ac]', 'Adaptive Composition')
t('settings.layout[1:7]', 'Speaker + 7')
t('settings.layout[one_main_nine_around]', 'Speaker + 9')
t('settings.layout[one_main_twelve_around]', 'Speaker + 12')
t('settings.layout[1:21]', 'Speaker + 21')
t('settings.layout[two_mains_eight_around]', '2 Speaker + 8')
t('settings.layout[2:21]', '2 Speaker + 21')
t('settings.layout[1:33]', 'Speaker + 33')
t('settings.layout[1:0]', 'Highlight')
t('settings.layout[2x2]', '2 x 2')
t('settings.layout[3x3]', '3 x 3')
t('settings.layout[4x4]', '4 x 4')
t('settings.layout[5x5]', '5 x 5')
*/

function getLayoutDetails(layout: string): React.ElementType {
    switch (layout as VideoLayoutOptionValues) {
        case VideoLayoutOptionValues.AdaptiveComposition:
            return adaptiveComposition;
        case VideoLayoutOptionValues.Speaker7:
            return speaker7;
        case VideoLayoutOptionValues.Speaker21:
            return speaker21;
        case VideoLayoutOptionValues.Speaker221:
            return speaker221;
        case VideoLayoutOptionValues.Speaker33:
            return speaker33;
        case VideoLayoutOptionValues.Equal22:
            return equal22;
        case VideoLayoutOptionValues.Equal33:
            return equal33;
        case VideoLayoutOptionValues.Equal44:
            return equal44;
        case VideoLayoutOptionValues.Equal55:
            return equal55;
        case VideoLayoutOptionValues.Highlight:
            return highlight;
        default:
            return unknown;
    }
}

export const Thumbnail: React.FC<
    React.ComponentProps<'svg'> & {
        active?: boolean;
        colorScheme?: ColorScheme;
        layout: string;
        selectLayout: (layout: string) => void;
    }
> = ({className, layout, colorScheme, selectLayout, children}) => {
    const {t} = useTranslation();
    const selectThisLayout = useCallback(
        () => selectLayout(layout),
        [layout, selectLayout],
    );
    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <div className={cx(styles.container, className)}>
                    <InteractiveElement
                        onClick={selectThisLayout}
                        className="mb-2"
                        data-testid={`${TestId.Layout}-${layout}`}
                        aria-label={t(`settings.layout[${layout}]`, {
                            defaultValue: layout,
                            nsSeparator: false,
                        })}
                    >
                        {children}
                    </InteractiveElement>
                    {layout && (
                        <Text
                            className={styles.caption}
                            colorScheme={colorScheme ?? defaultColorScheme}
                            htmlTag="span"
                            data-testid={`${TestId.LayoutLabel}-${layout}`}
                            isTruncated
                        >
                            {t(`settings.layout[${layout}]`, {
                                defaultValue: layout,
                                nsSeparator: false,
                            })}
                        </Text>
                    )}
                </div>
            )}
        </ThemeConsumer>
    );
};

export const LayoutThumbnail: React.FC<
    React.ComponentProps<'svg'> & {
        active?: boolean;
        colorScheme?: ColorScheme;
        layout: string;
        selectLayout: (layout: string) => void;
    }
> = ({active, className, colorScheme, layout, selectLayout, ...props}) => {
    const source = getLayoutDetails(layout);
    return (
        <Thumbnail layout={layout} selectLayout={selectLayout} {...props}>
            <ThemeConsumer>
                {({colorScheme: defaultColorScheme}) => (
                    <SvgImage
                        source={source}
                        className={cx(
                            styles.thumbnail,
                            styles[colorScheme ?? defaultColorScheme],
                            {
                                [styles.active]: !!active,
                            },
                            className,
                        )}
                        {...props}
                    />
                )}
            </ThemeConsumer>
        </Thumbnail>
    );
};

export const ImgLayoutThumbnail: React.FC<
    React.ComponentProps<'img'> & {
        svg: {primary: string; active: string};
        name: string;
        active: boolean;
        colorScheme?: ColorScheme;
    }
> = ({svg, name, active, colorScheme, ...props}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => (
            <img
                src={active ? svg.active : svg.primary}
                className={cx(
                    styles.thumbnail,
                    styles[colorScheme ?? defaultColorScheme],
                    {
                        [styles.active]: !!active,
                    },
                )}
                {...props}
                alt={name}
            />
        )}
    </ThemeConsumer>
);
