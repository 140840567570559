import React from 'react';
import cx from 'classnames';

import {TestId} from '../../../test/testIds';

import styles from './FrequencyBarsAudioMeter.module.scss';

export const FrequencyBar: React.FC<{
    className?: string;
}> = ({className}) => <div className={cx(styles.frequencyBar, className)} />;

export const FrequencyBars: React.FC<{
    barClassName?: string;
    barsNumber?: number;
}> = ({barClassName, barsNumber = 20}) => (
    <>
        {Array.from({length: barsNumber}, (_, i) => i).map(barNumber => (
            <FrequencyBar key={barNumber} className={barClassName} />
        ))}
    </>
);

export const FrequencyBarsAudioMeter: React.FC<
    React.ComponentProps<'div'> & {
        backgroundAnimationDuration?: number;
        barClassName?: string;
        barsWrapperClassName?: string;
        barsNumber?: number;
        className?: string;
        barsWrapperId?: string;
        showBackground?: boolean;
        showBackgroundAnimation?: boolean;
    }
> = ({
    backgroundAnimationDuration = 5,
    barClassName,
    barsWrapperClassName,
    barsNumber = 20,
    className,
    barsWrapperId,
    showBackground = false,
    showBackgroundAnimation = true,
    children,
    ...props
}) => (
    <div
        style={{
            animationDuration: showBackgroundAnimation
                ? `${backgroundAnimationDuration}s`
                : undefined,
        }}
        className={cx(
            styles.boxWrapper,
            showBackgroundAnimation && styles.backgroundProgressAnimation,
            showBackground && styles.background,
            className,
        )}
        data-testid={TestId.TestAudioInputIndicator}
        {...props}
    >
        <div
            className={cx(styles.barsWrapper, barsWrapperClassName)}
            id={barsWrapperId}
        >
            <FrequencyBars
                barsNumber={barsNumber}
                barClassName={barClassName}
            />
        </div>
        {children}
    </div>
);

export type FrequencyBarsAudioMeterProps = React.ComponentProps<
    typeof FrequencyBarsAudioMeter
>;
