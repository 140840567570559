import React from 'react';

import {Panel} from '@pexip/components';
import type {PanelProps} from '@pexip/components';

import styles from './SettingsPanel.module.scss';

export const SettingsPanel: React.FC<PanelProps> = props => (
    <Panel
        className={styles.panel}
        colorScheme="light"
        hasFooterShadow
        headerPadding="none"
        {...props}
    />
);
