import React, {useCallback} from 'react';
import cx from 'classnames';

import {Input} from '../../elements/Input/Input';
import {Button} from '../../elements/Button/Button';
import {ThemeConsumer} from '../../../themes/ThemeContext';
import type {ColorScheme} from '../../../types/variants';
import {TestId} from '../../../utils/testIds';

import styles from './NumberStepInput.module.scss';

export const NumberStepInput: React.FC<{
    name: string;
    label: string;
    onChange: (value: number) => void;
    colorScheme?: ColorScheme;
    value?: number;
    ariaLabelDecrement: string;
    labelDecrement?: string | React.ReactNode;
    ariaLabelIncrement: string;
    labelIncrement?: string | React.ReactNode;
    minValue?: number;
    maxValue?: number;
    isInputReadOnly?: boolean;
    isDisabled?: boolean;
    stepValue?: number;
}> = ({
    name,
    label,
    colorScheme,
    onChange,
    ariaLabelDecrement,
    labelDecrement = '-',
    ariaLabelIncrement,
    labelIncrement = '+',
    value = 0,
    minValue = 0,
    maxValue = Number.MAX_VALUE,
    isInputReadOnly,
    isDisabled,
    stepValue = 1,
}) => {
    const clamp = useCallback(
        (num: number): number => {
            return Math.min(Math.max(num, minValue), maxValue);
        },
        [minValue, maxValue],
    );

    const handleClick = useCallback(
        (addend: number) => {
            const newValue = clamp(value + addend);
            !isDisabled && onChange(newValue);
        },
        [value, clamp, onChange, isDisabled],
    );

    const handleInputValueChange = (value: string) => {
        const newValue = clamp(Number(value));
        onChange(newValue);
    };

    const hasName = Boolean(name);

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => {
                const scheme = colorScheme || defaultColorScheme;

                return (
                    <div className={styles.wrap}>
                        <Button
                            className={cx(
                                styles.button,
                                scheme === 'dark'
                                    ? styles.buttonBorderDark
                                    : styles.buttonBorderLight,
                            )}
                            colorScheme={scheme}
                            variant="translucent"
                            onClick={() => handleClick(-stepValue)}
                            disabled={value === minValue}
                            data-testid={
                                hasName
                                    ? `${name}-${TestId.NumberStepDecrement}`
                                    : TestId.NumberStepDecrement
                            }
                            aria-label={ariaLabelDecrement}
                            isDisabled={isDisabled}
                        >
                            {labelDecrement}
                        </Button>
                        <Input
                            className={styles.input}
                            colorScheme={scheme}
                            name={name}
                            label={label}
                            labelModifier="hidden"
                            type="number"
                            value={String(value)}
                            data-testid={
                                hasName
                                    ? `${name}-${TestId.NumberStepInput}`
                                    : TestId.NumberStepInput
                            }
                            onValueChange={handleInputValueChange}
                            readOnly={isInputReadOnly}
                            isDisabled={isDisabled}
                        />
                        <Button
                            className={cx(
                                styles.button,
                                scheme === 'dark'
                                    ? styles.buttonBorderDark
                                    : styles.buttonBorderLight,
                            )}
                            colorScheme={scheme}
                            variant="translucent"
                            onClick={() => handleClick(stepValue)}
                            disabled={value === maxValue}
                            data-testid={
                                hasName
                                    ? `${name}-${TestId.NumberStepIncrement}`
                                    : TestId.NumberStepIncrement
                            }
                            aria-label={ariaLabelIncrement}
                            isDisabled={isDisabled}
                        >
                            {labelIncrement}
                        </Button>
                    </div>
                );
            }}
        </ThemeConsumer>
    );
};
