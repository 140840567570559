import React, {useEffect, useState} from 'react';

import {MeetingLayout} from '@pexip/media-components';

import {useConfig} from '../config';
import {infinityService} from '../services/InfinityClient.service';
import {useCurrentHostLayout, useMeetingContext} from '../hooks/meeting';

const BASE_COLOR = '#FFFFFF';
const PRIMARY_COLOR = '#777777';
const ACTIVE_COLOR = '#0068F4';

// FIXME: this is all super hacky, first repalce we have because we cant override the color in the img tag,
// 2nd is to escape `#` as otherwise we cant get xml to load
// An alternative would be to load svg directly to the DOM, but then we execute some code we get from the API which isnt ideal?
const getSource = (source: string, color: string) =>
    source &&
    `data:image/svg+xml;utf8,${source.replaceAll('currentColor', BASE_COLOR).replaceAll('#BBBFC3', color)}`.replaceAll(
        '#',
        '%23',
    );

const getLayouts = (layouts: Record<string, string> | undefined) =>
    new Map(
        Object.entries(layouts ?? {}).map(([name, source]) => {
            const primary = getSource(source, PRIMARY_COLOR);
            const active = getSource(source, ACTIVE_COLOR);

            return [name, {primary, active}];
        }),
    );

export const Layouts: React.FC<{
    onBackClick: () => void;
    isConfirmationOpen: boolean;
    openConfirmation: () => void;
    closeConfirmation: () => void;
    close: (e: React.SyntheticEvent<HTMLElement>) => void;
}> = ({
    onBackClick,
    isConfirmationOpen,
    openConfirmation,
    closeConfirmation,
    close,
}) => {
    const meeting = useMeetingContext();
    const currentHostLayout = useCurrentHostLayout(
        meeting.getCurrentHostLayout,
    );
    const [
        showLayoutChangeConfirmationModal,
        setShowLayoutChangeConfirmationModal,
    ] = useConfig('showLayoutChangeConfirmationModal');

    const [layouts, setLayouts] =
        useState<Map<string, {primary: string; active: string}>>();

    useEffect(() => {
        const getAvailableLayouts = async () => {
            try {
                setLayouts(getLayouts(await infinityService.layoutSvgs({})));
            } catch (_e) {
                setLayouts(new Map());
            }
        };

        void getAvailableLayouts();
    }, []);

    if (!layouts) {
        return null;
    }

    return (
        <MeetingLayout
            onBackClick={onBackClick}
            currentLayout={currentHostLayout}
            setLayout={meeting.changeLayout}
            setShowLayoutChangeConfirmationModal={
                setShowLayoutChangeConfirmationModal
            }
            showLayoutChangeConfirmationModal={
                showLayoutChangeConfirmationModal
            }
            isConfirmationOpen={isConfirmationOpen}
            openConfirmation={openConfirmation}
            closeConfirmation={closeConfirmation}
            layouts={layouts}
            close={close}
        />
    );
};
