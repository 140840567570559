import {v4} from 'uuid';

import {logger} from './logger';

export function generateId(readableName?: string) {
    return `${readableName ? `${readableName}-` : ''}${v4()}`;
}

export function createPopupManager() {
    const popups = new Map<
        string,
        {
            popup: ReturnType<Window['open']>;
            shouldOpen?: (input: Record<string, unknown>) => boolean;
        }
    >();
    const intervals = new Set<number>();

    window.onbeforeunload = () => {
        for (const interval of intervals) {
            clearInterval(interval);
        }
        intervals.clear();
    };

    const add = (
        id: string,
        shouldOpen?: (input: Record<string, unknown>) => boolean,
    ) => {
        if (popups.has(id)) {
            logger.warn(
                `A popup with with ID:'${id}' has already been registered`,
            );
            return;
        }
        popups.set(id, {popup: null, shouldOpen});
    };

    const remove = (id: string) => {
        popups.delete(id);
    };

    const open = (
        id: string,
        openParams: Parameters<Window['open']>,
        context?: Record<string, unknown>,
    ) => {
        if (!popups.has(id)) {
            popups.set(id, {popup: null});
        }
        const popupCtx = popups.get(id);
        if (popupCtx?.popup && !popupCtx.popup.closed) {
            logger.warn(`Popup with ID:'${id}' is currently opened`);
            return;
        }
        if (
            popups.get(id)?.shouldOpen &&
            !popups.get(id)?.shouldOpen?.(context ?? {})
        ) {
            return;
        }

        const popup = window.open(...openParams);
        if (popupCtx) {
            popupCtx.popup = popup;
        }
        if (popup) {
            const intervalId = window.setInterval(() => {
                if (popup.closed) {
                    const popupWrapper = popups.get(id);
                    if (popupWrapper) {
                        popupWrapper.popup = null;
                    }
                    clearInterval(intervalId);
                    intervals.delete(intervalId);
                }
            }, 500);
            intervals.add(intervalId);
        }
    };

    const get = (id: string) => {
        return popups.get(id)?.popup;
    };

    return {add, remove, open, get};
}
