/// the Miller Shuffle Algorithm
//
// source: https://github.com/RondeSC/Miller_Shuffle_Algo
// license: Attribution-NonCommercial-ShareAlike
// Copyright 2022 Ronald R. Miller
// http://www.apache.org/licenses/LICENSE-2.0/

// Prime numbers
const p1 = 24317;
const p2 = 32141;
const p3 = 63629; // for shuffling 60,000+ indexes (only needs 32bit unsigned math)

/**
 * Pseudorandom index generator implemented with
 * Miller Shuffle Algorithm D variant     NEW April 2023
 * aka:   MillerShuffleAlgo_d
 *
 * Miller Shuffle, produces a shuffled Index given a base Index, a shuffle ID value and the length of the list being indexed.
 * For each inx: 0 to listSize-1, unique indexes are returned in a pseudo "random" order, utilizing minimum resources.
 * As such this Miller Shuffle algorithm is the better choice for a playlist shuffle.
 *
 * The 'shuffleID' is a 32bit value and be set by utilizing a PRNG. These bit determin the "random" shuffle.
 * Each time you want another pseudo random index from a current shuffle (incrementing 'inx')
 * you must be sure to pass in the "shuffleID" for that shuffle.
 * Note that you can exceed the listSize with the input 'inx' value and get very good results,
 * as the code effectively uses a secondary shuffle by way of using a 'working' modified value of the input shuffle ID.
 *
 *
 * @see {@link https://en.wikipedia.org/wiki/Pseudorandom_index_generator}
 * @see {@link https://github.com/RondeSC/Miller_Shuffle_Algo}
 *
 * @param baseIndex - The index to be shuffled
 * @param shuffleID - The seed number
 * @param listSize - The size of the list
 *
 * @returns shuffled index
 */
export function prig(
    baseIndex: number,
    shuffleID: number,
    listSize: number,
): number {
    // local randomizer copy
    const randR = Number(
        BigInt.asUintN(
            32,
            BigInt(shuffleID) ^ BigInt(Math.floor(baseIndex / listSize)),
        ),
    ); //  have baseIndex overflow effect the mix

    let si = (baseIndex + (randR & 0x7fffffff)) % listSize;

    const r1 = (randR % p1) + 42; // randomizing factors crafted empirically (by automated trial and error)
    const r2 = ((randR / 0x89) ^ r1) % p2;
    const r3 = (r1 + r2 + p3) % listSize;
    const r4 = r1 ^ r2 ^ r3;
    const rx = (Math.floor(randR / listSize) % listSize) + 1;
    const rx2 = (Math.floor(randR / listSize / listSize) % listSize) + 1;

    // perform the conditional multi-faceted mathematical spin-mixing
    if (si % 3 === 0) {
        si = (((si / 3) * p1 + r1) % Math.floor((listSize + 2) / 3)) * 3; // spin multiples of 3
    }
    if (si % 2 === 0) {
        si = (((si / 2) * p2 + r2) % Math.floor((listSize + 1) / 2)) * 2; // spin multiples of 2
    }
    if (si < Math.floor(listSize / 2)) {
        si = (si * p3 + r4) % Math.floor(listSize / 2);
    }
    if ((si ^ rx) < listSize) {
        si = si ^ rx; // flip some bits with Xor
    }
    si = (si * p3 + r3) % listSize; // relatively prime gears turning operation
    if ((si ^ rx2) < listSize) {
        si = si ^ rx2;
    }

    return si; // return 'Shuffled' index
}
