import {useEffect} from 'react';

import type {BreakoutParticipants} from '../types';
import {MainBreakoutRoomId} from '../constants';

import type {useBreakoutChangeParticipantRoom} from './useBreakoutChangeParticipantRoom';

export const useBreakoutUpdateParticipants = ({
    currentParticipants,
    participants,
    setParticipants,
    changeParticipantRoom,
}: {
    currentParticipants: BreakoutParticipants;
    participants: BreakoutParticipants;
    setParticipants: React.Dispatch<React.SetStateAction<BreakoutParticipants>>;
    changeParticipantRoom: ReturnType<typeof useBreakoutChangeParticipantRoom>;
}) =>
    useEffect(() => {
        const updatedParticipants = Array.from(
            currentParticipants.values(),
        ).flat();
        const breakoutParticipants = Array.from(participants.values()).flat();
        if (updatedParticipants.length > breakoutParticipants.length) {
            const joinedParticipants = updatedParticipants.filter(
                newP =>
                    !breakoutParticipants.find(
                        breakoutP => breakoutP.identity === newP.identity,
                    ),
            );
            if (joinedParticipants.length !== 0) {
                setParticipants(participants => {
                    const copy = new Map(participants.entries());
                    copy.get(MainBreakoutRoomId)?.push(...joinedParticipants);
                    return copy;
                });
            }
        } else if (updatedParticipants.length < breakoutParticipants.length) {
            const leftParticipants = breakoutParticipants.filter(
                breakoutP =>
                    !updatedParticipants.find(
                        p => p.identity === breakoutP.identity,
                    ),
            );
            // no room means delete
            for (const p of leftParticipants) {
                changeParticipantRoom(p.identity);
            }
        }
    }, [
        currentParticipants,
        participants,
        setParticipants,
        changeParticipantRoom,
    ]);
