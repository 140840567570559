import type {Dispatch, SetStateAction} from 'react';
import {useCallback} from 'react';

import type {MediaDeviceInfoLike} from '@pexip/media-control';
import type {PreviewInput, PreviewStreamController} from '@pexip/media';

import {logger} from '../logger';
import {onDeviceSelectChange} from '../utils/onDeviceSelectChange';
import type {DeviceError} from '../types';

import {useSelectedInput} from './useSelectedInput';

export const createPreviewVideoInputHook =
    ({
        get,
        getExpected,
        set,
    }: {
        get: () => MediaDeviceInfoLike | undefined;
        getExpected: () => MediaDeviceInfoLike | undefined;
        set: (value?: PreviewInput) => void;
    }) =>
    ({
        error,
        controller,
        setError,
    }: {
        error: DeviceError;
        controller: PreviewStreamController;
        setError: Dispatch<SetStateAction<DeviceError>>;
    }) => {
        const [preview] = useSelectedInput(
            controller.onVideoInputChanged,
            getExpected(),
        );

        const applyChanges = useCallback(() => {
            if (controller.videoInput?.deviceId !== get()?.deviceId) {
                const {videoInput} = controller;
                logger.debug({videoInput}, 'Save VideoInput');
                set(videoInput);
            }
            return false;
        }, [controller, get, set]);

        const setPreview = onDeviceSelectChange(
            setError,
            controller?.updateVideoInput,
        );

        return {
            error,
            preview,
            applyChanges,
            setPreview,
        };
    };
