import {useEffect} from 'react';

export const RESET_TIME = 3000;

export const useResetCopyMeetingLink = (
    setIsCopied: React.Dispatch<React.SetStateAction<boolean>>,
    isCopied: boolean,
) => {
    // biome-ignore lint/correctness/useExhaustiveDependencies: no idea
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsCopied(false);
        }, RESET_TIME);

        return () => {
            clearTimeout(timeout);
        };
    }, [setIsCopied, isCopied]);
};
