import {useEffect} from 'react';

import type {MediaController} from '@pexip/media';
import {isRequestPermissionNeeded} from '@pexip/media';
import type {Signal} from '@pexip/signal';
import type {IndexedDevices, MediaDeviceRequest} from '@pexip/media-control';

import {logger} from '../logger';

export const useMedia = (
    mediaService: MediaController,
    devicesChangedSubscribe: Signal<IndexedDevices>['add'],
    {audio, video}: MediaDeviceRequest,
) => {
    useEffect(() => {
        mediaService.tryAndGetUserMedia({audio, video});
        const remove = devicesChangedSubscribe(devices => {
            const requestAudio = devices.size('audioinput') > 0 && audio;
            const requestVideo = devices.size('videoinput') > 0 && video;
            if (
                // Avoid double GUM requests
                !mediaService.updatingMedia &&
                mediaService.media?.status &&
                !isRequestPermissionNeeded(mediaService.media?.status) &&
                (requestAudio || requestVideo)
            ) {
                mediaService.getUserMedia({
                    audio: requestAudio,
                    video: requestVideo,
                });
            }
        });

        return () => {
            logger.debug('Media cleanup');
            remove();
            mediaService.media?.release().catch((error: unknown) => {
                logger.error({error}, 'GUM cleanup failed');
            });
        };
    }, [audio, devicesChangedSubscribe, mediaService, video]);
};
