import React from 'react';

import styles from './SpeakingIndicator.module.scss';

export const SpeakingIndicator: React.FC<React.ComponentProps<'svg'>> = ({
    ...props
}) => (
    <svg
        viewBox="-2 0 12 1"
        width="24"
        height="10"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Speaking indicator"
        {...props}
    >
        <g>
            <path d="M 0,0.5 h 8" className={styles.grayBar} />
            <path d="M 0,0.5 h 8" className={styles.greenBar} />
        </g>
    </svg>
);
