import {useEffect, useMemo} from 'react';

import type {UserMediaStatus, UserMediaValidator} from '@pexip/media';
import type {Signal} from '@pexip/signal';

export const EASE_DURATION = 500;

export const isSpaceKeyboard = (code: string) =>
    code === 'Space' &&
    !['INPUT', 'TEXTAREA'].includes(document.activeElement?.tagName || '');

const preventSpaceKeyDefault = (e: KeyboardEvent) => {
    if (isSpaceKeyboard(e.code)) {
        e.preventDefault();
    }
};

/**
 * prevents Space key default behavior, such activating buttons when the focus on it.
 * it will not prevent however, if the active element is an text input.
 */
const preventSpaceKeyDefaultBehavior = () => {
    document.addEventListener('keyup', preventSpaceKeyDefault);
    return () => document.removeEventListener('keyup', preventSpaceKeyDefault);
};

const isValidStreamStatus = (
    areBothGranted: UserMediaValidator,
    isGrantedOnlyAudio: UserMediaValidator,
    streamStatus?: UserMediaStatus,
) =>
    streamStatus &&
    (areBothGranted(streamStatus) || isGrantedOnlyAudio(streamStatus));

type PTTStateType = {
    isMuted: undefined | boolean;
    isPressed?: boolean;
    easeMuteTimerId?: number;
};

export const initPushToTalkUI = (
    pushToTalkSignal: Signal<boolean>,
    pttState: PTTStateType = {
        isMuted: undefined,
        isPressed: false,
    },
) => {
    preventSpaceKeyDefaultBehavior();

    const cleanup = () => {
        document.removeEventListener('keydown', onSpacePressed);
        window.removeEventListener('blur', muteCall);
        clearTimeout(pttState.easeMuteTimerId);
    };

    const easeMute = (easeDuration = EASE_DURATION) => {
        pttState.easeMuteTimerId = window.setTimeout(() => {
            pttState.isPressed = false;
            clearTimeout(pttState.easeMuteTimerId);
        }, easeDuration);
    };

    const muteCall = () => {
        pushToTalkSignal.emit(false);
        easeMute();
    };

    const unmuteCall = () => {
        if (!pttState.isPressed && pttState.isMuted === true) {
            pttState.isPressed = true;
            document.addEventListener('keyup', onSpaceRelease, {
                once: true,
            });
            pushToTalkSignal.emit(true);
            window.addEventListener('blur', muteCall, {once: true});
        }
    };

    const onSpaceRelease = (e: KeyboardEvent) => {
        if (isSpaceKeyboard(e.code)) {
            muteCall();
        }
    };

    const onSpacePressed = (e: KeyboardEvent) => {
        if (isSpaceKeyboard(e.code)) {
            unmuteCall();
        }
    };

    document.addEventListener('keydown', onSpacePressed);

    return cleanup;
};

/**
 * Unmute call while the space keyboard is pressed. When released, the call is muted again.
 *
 * Remarks: space key default behavior, for example activates button, is disabled.
 */
export const createPushToTalkHook =
    (
        areBothGranted: UserMediaValidator,
        isGrantedOnlyAudio: UserMediaValidator,
        pushToTalkSignal: Signal<boolean>,
    ) =>
    ({
        isAudioInputMuted,
        setAudioInputMuted,
        streamStatus,
        isPushToTalkDisabled,
    }: {
        isAudioInputMuted: boolean;
        isPushToTalkDisabled: boolean;
        setAudioInputMuted: (input: boolean) => void;
        streamStatus?: UserMediaStatus;
    }) => {
        const isStreamValid = useMemo(
            () =>
                isValidStreamStatus(
                    areBothGranted,
                    isGrantedOnlyAudio,
                    streamStatus,
                ),
            [streamStatus, isGrantedOnlyAudio, areBothGranted],
        );

        useEffect(() => {
            if (isPushToTalkDisabled) {
                return;
            }

            const handlePushToTalkSignal = (enabled: boolean) => {
                if (isStreamValid) {
                    setAudioInputMuted(!enabled);
                }
            };

            const detach = pushToTalkSignal.add(handlePushToTalkSignal);

            return () => detach();
        }, [
            setAudioInputMuted,
            pushToTalkSignal,
            isStreamValid,
            isPushToTalkDisabled,
        ]);

        useEffect(() => {
            if (isStreamValid && !isPushToTalkDisabled) {
                return initPushToTalkUI(pushToTalkSignal, {
                    isMuted: isAudioInputMuted,
                });
            }
        }, [
            isStreamValid,
            isAudioInputMuted,
            pushToTalkSignal,
            isPushToTalkDisabled,
        ]);
    };
