import React, {useCallback, useEffect} from 'react';

import type {ConfigManager} from './ConfigManager';

const isCallable = <T>(t: unknown): t is (value: T) => T =>
    typeof t === 'function';

export const configHook =
    <Config extends Record<string, unknown>>(config: ConfigManager<Config>) =>
    <T extends keyof Config>(key: T) => {
        const [value, setValue] = React.useState(config.get(key));

        // Subscribe to the value instead of updating directly in case it's changed from elsewhere
        // biome-ignore lint/correctness/useExhaustiveDependencies: intended
        useEffect(
            () =>
                config.subscribe(key, (value: Config[T]) => {
                    setValue(value);
                }),
            [key],
        );

        // biome-ignore lint/correctness/useExhaustiveDependencies: intended
        const handleSetValue = useCallback(
            (
                input: ((prevValue: Config[T]) => Config[T]) | Config[T],
                persist?: boolean,
            ) => {
                // updating the value will trigger the subscriber above
                config.set({
                    key,
                    value: isCallable<Config[T]>(input)
                        ? input(config.get(key))
                        : input,
                    persist,
                });
            },
            [key],
        );

        return [value, handleSetValue] as const;
    };
