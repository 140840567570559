import type {
    AudioGraphOptions,
    AudioNodeInit,
    ThrottleOptions,
    DenoiseWorkletNodeInit,
    AnalyzerNodeInit,
    AudioGraph,
} from '@pexip/media-processor';
import type {MediaDeviceRequest} from '@pexip/media-control';
import {extractConstraintsWithKeys} from '@pexip/media-control';
import {createQueue, isEmpty, assert} from '@pexip/utils';
import {
    createAudioGraph,
    createAudioGraphProxy,
    createStreamSourceGraphNode,
    createStreamDestinationGraphNode,
    createAnalyzerSubscribableGraphNode,
    createDenoiseWorkletGraphNode,
    createAudioSignalDetector,
    createVADetector,
    createVoiceDetectorFromTimeData,
    createVoiceDetectorFromProbability,
    avg,
} from '@pexip/media-processor';

import type {TrackProcessor, DenoiseParams, AudioContentHint} from './types';
import {PROCESSOR_LABELS} from './constants';
import {logger} from './logger';
import {isAudioContentHint} from './typeGuard';
import {createMediaTrack} from './utils';

type AudioNodeInits = AudioNodeInit[];
/**
 * A function to be called to create the AudioNodes needed for the graph
 * creation
 *
 * @param media - Media to be used for the AudioGraph creation
 */
type CreateNodes = (track: MediaStreamTrack) => AudioNodeInits;

interface AudioProcessOptions {
    /**
     * An option is being passed to AnalyserNode creation when used
     * @see https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/fftSize
     *
     * @defaultValue 2048
     */
    fftSize?: number;
    /**
     * Params needed for setting up noise suppression WebAssembly and
     * AudioWorklet
     */
    denoiseParams?: DenoiseParams;
    /**
     * Update frequency for analyzer per second
     *
     * @defaultValue 0.5
     */
    analyzerUpdateFrequency?: number;
    /**
     * Audio Signal Detection duration in second
     *
     * @defaultValue 4.0
     */
    audioSignalDetectionDuration?: number;
    /**
     * Callback when Voice Activity detected
     */
    onVoiceActivityDetected?: () => void;
    /**
     * Callback when Audio Signal detected
     */
    onAudioSignalDetected?: (silent: boolean) => void;
    /**
     * @see AudioGraphOptions
     */
    audioGraphOptions?: AudioGraphOptions;
    /**
     * Whether or to enable this processor
     */
    shouldEnable: () => boolean;
    /**
     * Insert additional nodes between the source and destination
     */
    createNodes?: CreateNodes;
    /**
     * Silent threshold, how large the value of the sample is considered as
     * silent in FFTed time domain
     */
    silentThreshold?: number;
    label?: string;
}

/**
 * Fetch the wasm when it doesn't exist from the provided, otherwise do nothing
 *
 * @param denoiseWasm - The wasm if it exists
 * @param wasmURL - The URL for the fetching
 */
const fetchDenoiseWasm = async (
    denoiseWasm: ArrayBuffer | undefined,
    wasmURL: string | undefined,
) => {
    if (!wasmURL || denoiseWasm) {
        return denoiseWasm;
    }
    return await (await fetch(wasmURL)).arrayBuffer();
};

interface AudioStreamProcessorProps {
    audioGraphOptions?: AudioGraphOptions;
    denoiseWasm?: ArrayBuffer;
    vad?: boolean;
    asd?: boolean;
    denoise?: boolean;
    analyzerBuffer?: Float32Array;
    denoiseNode?: DenoiseWorkletNodeInit;
    additionalAudioSourceNode?: AudioNodeInit<
        MediaStreamAudioSourceNode,
        MediaStreamAudioSourceNode
    >;
    mixerNode?: AudioNodeInit<ChannelMergerNode, ChannelMergerNode>;
    analyzer?: AnalyzerNodeInit;
    contentHint?: AudioContentHint;
    audioGraph?: AudioGraph;
}

const FEATURE_KEYS: ['denoise', 'vad', 'asd', 'contentHint'] = [
    'denoise',
    'vad',
    'asd',
    'contentHint',
];
type FeaturePropKeys = (typeof FEATURE_KEYS)[number];
type FeatureProps = Pick<AudioStreamProcessorProps, FeaturePropKeys>;

const getAudioConstraints = extractConstraintsWithKeys(FEATURE_KEYS);

export const updateFeatureProps = (
    constraints: MediaDeviceRequest['audio'],
    props: FeatureProps,
) => {
    const extracted = getAudioConstraints(constraints);
    return FEATURE_KEYS.reduce((accm, key) => {
        switch (key) {
            case 'contentHint': {
                const [[feature] = []] = extracted[key];
                if (isAudioContentHint(feature) && props[key] !== feature) {
                    props[key] = feature;
                    accm[key] = feature;
                    return accm;
                }
                return accm;
            }
            case 'denoise':
            case 'asd':
            case 'vad': {
                const [feature] = extracted[key];
                if (feature !== undefined && props[key] !== feature) {
                    props[key] = feature;
                    accm[key] = feature;
                    return accm;
                }
                return accm;
            }
            default:
                return accm;
        }
    }, {} as FeatureProps);
};

/**
 * Create a Audio Stream Processor and will own the stream passed-in
 */
export const createAudioStreamProcess = ({
    analyzerUpdateFrequency = 0.5, // 0.5 Hz
    audioGraphOptions,
    audioSignalDetectionDuration = 4.0, // 4 seconds
    clock,
    createNodes,
    denoiseParams,
    fftSize = 2048, // FFT size
    onAudioSignalDetected,
    onVoiceActivityDetected,
    shouldEnable,
    silentThreshold = 10.0 / 32767, // At least one LSB 16-bit data (compare is on absolute value).
    throttleMs = 3000, // 3 seconds
    label = PROCESSOR_LABELS.AudioProcessor,
}: AudioProcessOptions & ThrottleOptions): TrackProcessor => {
    const props: AudioStreamProcessorProps = {
        audioGraphOptions,
        vad: false,
        asd: false,
        denoise: false,
    };

    const detectAudio =
        onAudioSignalDetected &&
        createAudioSignalDetector(() => !!props.asd, onAudioSignalDetected);

    const detectVA =
        onVoiceActivityDetected &&
        createVADetector(onVoiceActivityDetected, () => !!props.vad, {
            throttleMs,
            clock,
        });
    const detectVAFromTimeData = detectVA?.(createVoiceDetectorFromTimeData());

    const createDenoiseNode = async (denoise: boolean | undefined) => {
        if (!denoise) {
            return undefined;
        }
        if (props.denoiseNode) {
            return props.denoiseNode;
        }
        if (denoiseParams?.workletModule) {
            try {
                await props.audioGraph?.addWorklet(
                    denoiseParams?.workletModule,
                    denoiseParams?.workletOptions,
                );
            } catch (error: unknown) {
                logger.error(
                    {
                        label,
                        error,
                        moduleURL: denoiseParams?.workletModule,
                        options: denoiseParams?.workletOptions,
                    },
                    'Failed add worklet',
                );
                return undefined;
            }
        }
        try {
            props.denoiseWasm = await fetchDenoiseWasm(
                props.denoiseWasm,
                denoiseParams?.wasmURL,
            );
        } catch (error: unknown) {
            logger.error(
                {
                    label,
                    error,
                    url: denoiseParams?.wasmURL,
                    prevWasm: props.denoiseWasm,
                },
                'Failed to fetch denoise wasm',
            );
            return undefined;
        }
        const detectVAFromProbability = detectVA?.(
            createVoiceDetectorFromProbability(),
        );
        if (props.denoiseWasm) {
            const denoise = createDenoiseWorkletGraphNode(
                props.denoiseWasm,
                vads => {
                    detectVAFromProbability?.(avg(vads));
                },
            );
            props.denoiseNode = denoise;
            return denoise;
        }
    };

    const createAnalyzer = () => {
        const shouldUseAnalyzer = () =>
            !!((props.vad && !props.denoise) || props.asd) &&
            (detectAudio || detectVA);
        if (!shouldUseAnalyzer()) {
            return;
        }
        if (props.analyzer) {
            return props.analyzer;
        }
        const detectSilentAudio = detectAudio?.(
            createQueue<number[]>(
                audioSignalDetectionDuration / analyzerUpdateFrequency,
            ),
            silentThreshold,
        );
        const analyzer = createAnalyzerSubscribableGraphNode({
            updateFrequency: analyzerUpdateFrequency,
            messageHandler: analyzer => {
                if (shouldUseAnalyzer()) {
                    if (!props.analyzerBuffer) {
                        // Only Create the buffer when needed
                        props.analyzerBuffer = new Float32Array(fftSize);
                    }
                    analyzer.getFloatTimeDomainData(props.analyzerBuffer);
                    const data = Array.from(props.analyzerBuffer);
                    detectSilentAudio?.(data);
                    !props.denoise && detectVAFromTimeData?.(data);
                }
            },
            fftSize,
        });
        props.analyzer = analyzer;
        return analyzer;
    };

    return async prevMediaTrack => {
        updateFeatureProps(prevMediaTrack.getConstraints(), props);
        const shouldProcessAudio =
            shouldEnable() &&
            (!!onVoiceActivityDetected ||
                !!onAudioSignalDetected ||
                !!props.asd ||
                !!props.vad ||
                !!props.denoise ||
                !!createNodes);
        if (!shouldProcessAudio || !prevMediaTrack.track) {
            return prevMediaTrack;
        }
        const source = createStreamSourceGraphNode(
            new MediaStream([prevMediaTrack.track]),
        );

        const destination = createStreamDestinationGraphNode();

        const otherNodes = createNodes?.(prevMediaTrack.track) ?? [];
        const analyzer = createAnalyzer();
        const initialAudioNodeConnection = [
            [source, ...otherNodes, destination],
            [source, analyzer],
        ];
        logger.debug(
            {initialAudioNodeConnection, label},
            'Initial AudioNodeConnection',
        );
        const audioGraph = createAudioGraphProxy(
            createAudioGraph(
                initialAudioNodeConnection,
                props.audioGraphOptions,
            ),
            {
                connect: (target, args) => {
                    logger.debug({label, target, args}, 'connect nodes');
                },
                disconnect: (target, args) => {
                    logger.debug({label, target, args}, 'disconnect nodes');
                },
            },
        );

        props.audioGraph = audioGraph;
        const denoiseNode = await createDenoiseNode(props.denoise);
        const connectDenoise = (node: DenoiseWorkletNodeInit | undefined) => {
            if (node) {
                audioGraph.disconnect([source, ...otherNodes, destination]);
                audioGraph.connect([source, node, ...otherNodes, destination]);
            }
        };
        connectDenoise(denoiseNode);

        const track = destination?.node?.stream.getAudioTracks().at(0) ?? null;
        assert(track, 'No audio track available');
        // Inherit contentHint from previous track
        track.contentHint = prevMediaTrack.track.contentHint;

        const release = async () => {
            logger.debug({label}, 'Release Media');
            track.stop();
            // Release Props
            await audioGraph.release();
            props.denoiseNode = undefined;
            props.analyzer = undefined;
            props.audioGraph = undefined;
        };
        const mute = (mute: boolean) => {
            if (track) {
                track.enabled = !mute;
            }
        };

        return createMediaTrack({
            label,
            kind: 'audioinput',
            constraints: prevMediaTrack.getConstraints(),
            previousMediaTrack: prevMediaTrack,
            input: prevMediaTrack.input,
            expectedInput: prevMediaTrack.expectedInput,
            mute,
            track,
            release,
            applyConstraints: async constraints => {
                if (isEmpty(constraints)) {
                    return;
                }
                const features = updateFeatureProps(constraints, props);
                logger.debug(
                    {label, constraints: constraints, features},
                    'apply audio constraints',
                );
                if (
                    isEmpty(features) ||
                    ['closed', 'closing'].includes(audioGraph.state)
                ) {
                    return;
                }
                const denoiseNode = await createDenoiseNode(props.denoise);
                if (denoiseNode) {
                    if (!source.hasConnectedTo(denoiseNode)) {
                        connectDenoise(denoiseNode);
                    }
                } else {
                    if (props.denoiseNode) {
                        audioGraph.disconnect([
                            source,
                            props.denoiseNode,
                            ...otherNodes,
                            destination,
                        ]);
                        audioGraph.connect([
                            source,
                            ...otherNodes,
                            destination,
                        ]);
                        audioGraph.releaseInit(props.denoiseNode);
                        props.denoiseNode = undefined;
                    }
                }
                const analyzer = createAnalyzer();
                if (analyzer) {
                    audioGraph.connect([source, analyzer]);
                } else {
                    if (props.analyzer) {
                        audioGraph.disconnect([source, props.analyzer]);
                        audioGraph.releaseInit(props.analyzer);
                        props.analyzer = undefined;
                    }
                }
            },
            getSettings: () => {
                const denoise =
                    !!props.denoiseNode &&
                    source.hasConnectedTo(props.denoiseNode);
                const asd = !!props.asd;
                const vad = !!props.vad;
                const contentHint = track.contentHint as AudioContentHint;
                const audioSettings = {
                    denoise,
                    asd,
                    vad,
                    contentHint,
                };
                return audioSettings;
            },
        });
    };
};
