import React from 'react';

import {FullSizeWindow, isBackgroundImageSrcEmpty} from '@pexip/components';
import type {
    FullSizeWindowCommonProps,
    FullSizeWindowColorBackgroundProps,
    FullSizeWindowImageBackgroundProps,
    FullSizeWindowBackgroundVariant,
} from '@pexip/components';

import {useBrandImage} from '../hooks/useBrandImage';
import {useBranding} from '../branding/Context';

type InfinityConnectFullSizeWindowBackgroundProps =
    | FullSizeWindowColorBackgroundProps
    | Omit<
          FullSizeWindowImageBackgroundProps,
          | 'backgroundImageSrc'
          | 'backgroundImagePath'
          | 'backgroundBlendMode'
          | 'overlayOpacity'
          | 'overlay'
      >;

type InfinityConnectFullSizeWindowProps = FullSizeWindowCommonProps &
    InfinityConnectFullSizeWindowBackgroundProps;

export const BrandedFullSizeWindow: React.FC<
    React.ComponentPropsWithoutRef<'div'> &
        InfinityConnectFullSizeWindowProps & {
            fallbackBackground?: FullSizeWindowBackgroundVariant;
        }
> = ({background: backgroundProp, fallbackBackground = 'dark', ...props}) => {
    const backgroundImageSrc = useBrandImage('background');
    const overlay = useBranding('overlay');
    const overlayOpacity = useBranding('overlayOpacity');

    const backgroundBlendMode = overlay === 'light' ? 'lighten' : 'darken';
    const background =
        backgroundProp === 'image' &&
        isBackgroundImageSrcEmpty(backgroundImageSrc)
            ? fallbackBackground
            : backgroundProp;

    if (background === 'image' && backgroundImageSrc) {
        return (
            <FullSizeWindow
                {...props}
                background={background}
                backgroundImageSrc={backgroundImageSrc}
                backgroundBlendMode={backgroundBlendMode}
                overlayOpacity={overlayOpacity}
                overlay={overlay}
            />
        );
    }
    if (background !== 'image') {
        return <FullSizeWindow {...props} background={background} />;
    }
    return null;
};
