import React, {useEffect, useCallback, useRef} from 'react';

import {Quality} from '@pexip/peer-connection-stats';
import {notificationToastSignal} from '@pexip/components';

import {CallQualityAlert} from '../views/CallQualityAlert/CallQualityAlert.view';
import type {StreamQuality} from '../types';
import {NetworkState} from '../types';

const CLOSE_ALERT_TIMEOUT = 3000;

const openNotificationToast = () => {
    notificationToastSignal.emit([
        {
            message: <CallQualityAlert />,
            isDanger: true,
            isInterrupt: true,
            colorScheme: 'light',
            timeout: 0,
        },
    ]);
};

const closeAlert = () => {
    notificationToastSignal.emit({close: true});
};

export const useCallQualityToast = ({
    callQuality,
    networkState = NetworkState.Connected,
}: {
    callQuality: Quality;
    getStreamQuality: () => StreamQuality;
    networkState?: NetworkState;
}) => {
    const timeoutId = useRef<number>(0);

    const cleanupTimeout = useCallback(() => {
        if (timeoutId.current) {
            clearTimeout(timeoutId.current);
            timeoutId.current = 0;
        }
    }, []);

    useEffect(() => {
        if (networkState === NetworkState.Reconnecting) {
            return closeAlert();
        }
        if (callQuality === Quality.BAD || callQuality === Quality.TERRIBLE) {
            cleanupTimeout();
            openNotificationToast();
        } else {
            timeoutId.current = window.setTimeout(
                closeAlert,
                CLOSE_ALERT_TIMEOUT,
            );
        }

        return cleanupTimeout;
    }, [callQuality, cleanupTimeout, networkState]);
};
